import {addSolution} from "../../../endpoints/solutions";
export const solutions = [
    {
        "solution_id": 1,
        "abbreviation": "at",
        "solution_name": "auto-tag"
    },
    {
        "solution_id": 2,
        "abbreviation": "vs",
        "solution_name":"visual-search"
    },
    {
        "solution_id": 3,
        "abbreviation": "sr",
        "solution_name":"similar-recommendations"
    },
    {
        "solution_id": 4,
        "abbreviation": "as",
        "solution_name":"auto-scale"
    }
]

export const createClientSolution = (allState) => {
    let data = {};
    data.client_id = allState.client_id;
    data.solution_id = parseInt(allState.selectedSol);
    data.business_model_id = parseInt(allState.selectedBusinessModel);
    if(parseInt(allState.limitation) > 0) {            
        data.limitation = parseInt(allState.limitation);
    } else {
        data.limitation = parseInt(0);
    }

    if(parseInt(allState.dailyLimit) > 0) {            
        data.limit_by_day = parseInt(allState.dailyLimit);
    } else {
        data.limit_by_day = parseInt(0);
    }
    // data.limitation = parseInt(allState.limitation);
    // data.limit_by_day = parseInt(allState.dailyLimit);
    data.active = true;
    // console.log(data)
    addSolution(data, allState.select).then(res => {
        // console.log(allState.select, 'primaaa')
        // console.log(res)
        if(res.responseStatus !== 200){
            allState.setErrorMsg(res.detail);
        } else {
            allState.setAddSolutions(false);
            allState.reseter ? allState.setReseter(false) : allState.setReseter(true)
        }
    })
}