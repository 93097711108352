import {idFilesURL} from "./baseURL";

const token = JSON.parse(localStorage.getItem('loginToken'));
// http://10.0.5.1/<env>/<version>/admin/files/<client_id>
//http://10.0.5.1/dev/v3/admin
 const updateIdFiles = async (version, client_id, data, select ) => {
    let urlAnn = () => {
        if( select === 'clients') {
            return 'False'
        } else {
            return 'True'
        }
    }
    const annDb = urlAnn()
    try {
        return await fetch(`${idFilesURL}/v${version}/admin/files/${client_id}?annotation_db=${annDb}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: new Headers({
                "Authorization": `Bearer ${token.access_token}`,
                "Content-Type": "application/json",
            })
        }) 
        .then(response => response.json())
        .then(responseJson => {
          return responseJson;
        })
        .catch(err => {
            console.log(err)
            return err;
        })
    } catch (err) {
        return err;
    } 
      
}

export default updateIdFiles;