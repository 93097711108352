import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';

const ErrorModal = (props) => {

    const {msg, exitFunc} = props;

    const exit = () => {
        document.body.style.overflowY = "auto";
        exitFunc();
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";
    }, [])

    return ReactDOM.createPortal(
        <div onClick={e=>{e.stopPropagation(); exit()}} className="ui dimmer modals visible active">
             <div onClick={e=>e.stopPropagation()} className="ui standard modal visible active modal-class-error" style={{minWidth:"20%"}}>
                <div className="content modal-content">
                    <div className="modal-title">{msg}</div> 
                    <button onClick={exit} className="button-create">OK</button>
                </div>
                
             </div>
        </div>, document.getElementById('modal')
    )
}

export default ErrorModal;