import React, {  useEffect , useState} from "react";
import {useStore} from 'react-context-hook';
import { Chart } from 'react-charts'
// import moment from 'moment'
const FrequentHours = () =>{
    // const [analytics,] = useStore("analytics",[])
    //moment(activity.date).format('DD-MM-YYYY')
    const [frequentHours,]=useStore("frequentHours",[])

    const [data,setData] = useState([])


    useEffect(()=>{
      if(frequentHours.length!==0)
      setData([
        {
          label: 'Number of uploads',
          color: "rgb(85,111,181)",
          fontStyle:"Avenir",
          lineWidth:5,
          scaleStepWidth:1,
          data : frequentHours,
        }
      ]) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[frequentHours])

    let options ={ scales: {
        yAxes: [{
            ticks: {min: 0, max :30, stepSize: 3, maxTicksLimit:10,beginAtZero:true},
            gridLines:{display:false,drawBorder: true,},
          }]
        }
      }
     
      const axes = React.useMemo(
        () => [
          { primary: true, type: 'ordinal',position: 'bottom'},
          { type: 'linear', position: 'left', showGrid:false, gridLines:{display:false,drawBorder: true,},
          ticks: {min: 0, max :30, stepSize: 3, maxTicksLimit:10,beginAtZero:true},
        }
        ],
        []
      )
    // console.log(analytics)
    // console.log("dataaa",data)
    return(
      <div className="frequent-hours">
          <div className="content">
            <div className="column-direction" style={{height:"100%"}}>
                <div className="direction-row"  style={{marginBottom:"1rem"}}>
                <div className="title">Average Hourly Upload Frequency</div>
                </div>
                <div style={{width: '100%',height: '100%'}}>
                {
                <Chart data={data} axes={axes} tooltip options={options} />
                }
                </div>
            </div>
          </div>
      </div>
    )
}

export default FrequentHours