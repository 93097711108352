// export const baseURL = "http://10.0.5.1/dev2/v3/admin";
// export const baseURL = "https://admin-dashboard-66ztjy65la-uc.a.run.app/v3/admin";
export const baseURL = "https://pva.pixyle.ai/v3/admin";

// export const baseLoginApiURL ="http://10.0.5.1/dev2/v4/users";
// export const baseLoginApiURL = "https://loginapi-66ztjy65la-uc.a.run.app/v4/users";
export const baseLoginApiURL = "https://pva.pixyle.ai/v4/users";

// export const idFilesURL ="http://10.0.5.1/dev2";
export const idFilesURL ="https://pva.pixyle.ai"
// export const idFilesURL ="https://admin-dashboard-66ztjy65la-uc.a.run.app";

// export const dropidFilesURL = "https://autotagapi-66ztjy65la-uc.a.run.app";
export const dropidFilesURL = "https://pva.pixyle.ai";
