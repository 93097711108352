import React, { Component } from 'react';
import {login} from '../../endpoints/login';
import img from "../../assets/img_login.svg";
import LoginModal from "../Modals/UtilityModals/LoginModal";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { userName: "", password: "", clicked :false, message:"",  }
        this.clickLogin = this.clickLogin.bind(this);
    }
    unclick = () =>{
        this.setState({...this.state,clicked:false,message:""})
    }


    clickLogin = event => {
        event.preventDefault();
        event.stopPropagation();
        let data = {};
        data.username = this.state.userName;
        data.password = this.state.password;
        try {
            login(data).then (async res => {
                if(!res.status){
                    localStorage.setItem("loginToken", JSON.stringify(res));
                    // console.log("res: ", res);
                    window.location.assign('/');
                
                } else if( res.status === 429){
                    if(res.message){
                        this.setState({...this.state, clicked:true, message:"Rate Limit Exceeded. Please Try Again After Few Minutes!"})
                    }
                    this.setState({...this.state, message:res.detail, recovery:true})
                }
                else {
                    this.setState({...this.state,clicked:true,message:res.detail})
                    // console.log(this.state.clicked)
                    }
                })
        } 
        catch(err){
            // console.log("Error: ", err)
        }
    }
    
    setStateFromInput = event => {
        var obj = {};
        obj[event.target.name] = event.target.value.trim();
        this.setState(obj);
    };

    render(){
        return(
            <div className="login-div">
                <div className="login-form-bg">
                    <div className="login-form">
                        <div className="login-img">
                            <img src={img} alt="Woman holding a phone illustration."/>
                        </div>
                        <div className="login-input">
                            <div className="column-direction">
                            <h1>Log In</h1>
                            {/* <p>Upload your collections and let us do the heavy lifting</p> */}
                            <form className="ui form" onSubmit={(e)=>this.clickLogin(e)}>
                              <div className="ui field">
                                  <label>Username</label>
                                  <input
                                    className="formField u-mrs"
                                    type="text"
                                    id="username"
                                    name="userName"
                                    placeholder="Username"
                                    required
                                    onChange={this.setStateFromInput}>
                                  </input>
                                  </div>
                              <div className="ui field">
                                  <label>Password</label>
                                  <input
                                      className="formField u-mrs"
                                      type="password"
                                      id="password"
                                      name="password"
                                      placeholder="Password"
                                      required
                                      onChange={this.setStateFromInput}
                                    ></input>
                              </div>
                              <div className="field  centerThis u-mrs">
                                  <button className="button-login" style={{padding:"0"}}>Log me in</button>
                              </div>
                            </form>
                            <div className="ui divider"></div>
                            <p>Have problem logging in? <a href="https://www.pixyle.ai/"><b>Contact Us</b></a> so we can solve it right away...</p>
                            </div>
                        </div>
                    </div>
                </div>

            {
                this.state.clicked?
                <LoginModal
                show={this.state.clicked}
                title={this.state.message}
                backTo="/login"
                getBack={()=>this.unclick()} 
              />
              :""
              }

            </div>
        )
    }
}

export default Login