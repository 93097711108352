export const mapModels = (models, nextModelId, setNextModelId) => {
    if(!nextModelId) {
        setNextModelId(models[models.length - 1].bm_id);
    }
    // console.log(models[models.length - 1].bm_id);
    return models.map(model => {
        return (
            <tr key={model.bm_id} className="model-row">
                <td className="td-first-round-edges td">{model.bm_id}</td>
                <td className="td" >{model.name}</td>
                <td className="td td-last-round-edges" >{model.duration_in_days}</td>
            </tr>)
    })
}

export const mapSelectiveModels = (models, selectedModelId, setSelectedModelId) => {
    // console.log(models[models.length - 1].bm_id);
    return models.map(model => {
        if (model.bm_id === selectedModelId){
            return (
                <tr onClick={()=>setSelectedModelId(model.bm_id)} key={model.bm_id} className="model-row selected">
                    <td className="td-first-round-edges td cursore">{model.bm_id}</td>
                    <td className="td cursore" >{model.name}</td>
                    <td className="td td-last-round-edges cursore" >{model.duration_in_days}</td>
                </tr>)
        }
        else {
            return (
                <tr onClick={()=>setSelectedModelId(model.bm_id)} key={model.bm_id} className="model-row">
                    <td className="td-first-round-edges td cursore">{model.bm_id}</td>
                    <td className="td cursore" >{model.name}</td>
                    <td className="td td-last-round-edges cursore" >{model.duration_in_days}</td>
                </tr>)
        }
        
    })
}