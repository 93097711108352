import React from 'react';
const Empty = () => {
    return (
        <tr className="empty-row">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td> 
    </tr>
    )
    
}
export default Empty;
