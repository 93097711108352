import React, { useEffect, useState } from 'react'
// import Calendar from '../../Dashboard/Calendar'
import { useStore } from 'react-context-hook';
import { overView } from '../../../endpoints/overView'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';

const CalendarModal = ({ closeModal, analytics }) => {

    const goBack = () => {

        closeModal(false)

    }
    const [value, onChange] = useState(new Date());
    const [resultOverView, setResultOverView] = useState({})
    const [startMonth, setStartMonth] = useState(null)
    const [endMonth, setEndMonth] = useState(null)
    const [selectedStartMonth, setSelectedStartMonth] = useState(null)
    const [selectedEndMonth, setSelectedEndMonth] = useState(null)
    const [select] = useStore('Select', 'clients')
    const [loading, setLoading] = useState(false)

    // console.log(value)
    let startDate = Date.parse(value[0])
    let endDate = Date.parse(value[1])

    const clientId = analytics.analytics[0].client_id

    //   console.log(analytics)
    const convertStartDate = () => {
        if (startDate) {
            let day = new Date(startDate).getDate();
            let month = new Date(startDate).getMonth() + 1;
            let year = new Date(startDate).getFullYear();
            return (year + "-" + month + "-" + day)
        } else {
            return null
        }

    }
    // console.log(convertStartDate())

    const convertEndDate = () => {
        if (endDate) {
            let day = new Date(endDate).getDate();
            let month = new Date(endDate).getMonth() + 1;
            let year = new Date(endDate).getFullYear();
            return (year + "-" + month + "-" + day)
        } else {
            return null
        }
    }
    // console.log(convertEndDate())

    // const myFunction = () => {
    //     setTimeout(() => setLoading(false), 3000);
    // }

    useEffect(() => {
        if (convertStartDate() !== null && convertStartDate() !== undefined && convertEndDate() !== null && convertEndDate() !== undefined) {
            setLoading(true)
            overView(clientId, analytics.selectedSol, convertStartDate(), convertEndDate(), select).then(res => {            
                setResultOverView(res)  
                setLoading(false)
            })
            // if(startMonth !== null && startMonth !== undefined && endMonth !== null && endMonth !== undefined) {
            //     overView(clientId,analytics.selectedSol,startMonth,endMonth).then(res => {
            //         setResultOverView(res) 
            //     })
            // }
        }
    }, [value, startMonth])

    useEffect(() => {
        if (startMonth !== null && startMonth !== undefined && endMonth !== null && endMonth !== undefined) {
            overView(clientId, analytics.selectedSol, startMonth, endMonth, select).then(res => {

                setResultOverView(res)
                onChange([selectedStartMonth, selectedEndMonth])

            })
        }
    }, [startMonth])


    const solutionSelected = () => {
        if (analytics.selectedSol === 1) {
            return 'Automatic Tagging';
        } else if (analytics.selectedSol === 2) {
            return 'Visual Search';
        } else if (analytics.selectedSol === 3) {
            return 'Similar Recommendations';
        } else if (analytics.selectedSol === 4) {
            return 'Automatic Rescale';
        }

    }

    const monthFunk = (value) => {
        let day = new Date(value).getDate();
        let month = new Date(value).getMonth() + 1;
        let year = new Date(value).getFullYear();
        setStartMonth(year + "-" + month + "-" + day)
        setSelectedStartMonth(value)

        let parsedEndDate = new Date(year, month, 0)
        let endday = new Date(parsedEndDate).getDate();
        setEndMonth(year + "-" + month + "-" + endday)
        setSelectedEndMonth(parsedEndDate)
    }

    // console.log(startMonth, endMonth)


    // console.log(value)
    // console.log(analytics.selectedSol)
    return (
        <div onClick={() => goBack()} className="ui dimmer modals visible active">
            <div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active modal-class basicc" style={{ minWidth: "30%" }}>
                <div className="ui modal visible active">
                    <div className="image content">
                        <div className="ui medium image">
                            <Calendar
                                onChange={onChange}
                                value={value}
                                selectRange={true}
                                onClickMonth={monthFunk}
                            // returnValue={"range"}
                            />
                        </div>
                        <div className="description">
                            <div className="ui header"><h1>{`Images processed for solution : ${solutionSelected()}`}</h1></div>
                            <h3>Images Processed : { loading ? <span className='loader'></span> : resultOverView.result}</h3>
                            {/* <h3>The user is <b>{loading ? <h3 className='loader'></h3> : 'not'}</b> logged in.</h3> */}


                        </div>
                    </div>
                    <div className="actions">
                        <button onClick={() => goBack()} className="button-all">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalendarModal
