import React from 'react';
import logo from "../../assets/logo.svg";
import logoutIcon from "../../assets/logout.svg";
import {exit} from "../../endpoints/validation"
const TopNavbar = () => {
    return (
        <div className="ui secondary menu top">
            <div className="top-logo" onClick={() => {window.location.assign("/")}}>
                <img src={logo} alt="dashboard_logo" style={{maxHeight:"3.5rem"}} />
            </div>
            <div className="right menu">
                <div className="item">Welcome, Admin</div>
                <div onClick={exit} className="item logout">
                    Log Out 
                    <img  src={logoutIcon} alt="log_out" className="svg-gray"/>
                </div>
            </div>
        </div>
    )
}
export default TopNavbar;