import React, {useEffect, useState} from 'react';
import {useStore} from 'react-context-hook';
import ReactDOM from 'react-dom';
import {getAllBusinessModels} from "../../../endpoints/businessModel";
import {register} from "../../../endpoints/register";
import ErrorModal from "../UtilityModals/ErrorModal";
import YesNoModal from '../UtilityModals/YesNoModal'
import CreateComfirmModal from '../UtilityModals/CreateComfirmModal'
const Register = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPass, setRepeatPass] = useState('');
    const [mail, setMail] = useState('');
    const [company, setCompany] = useState('');
    const [businessModelId, setBusinessModelId] = useState(0);
    const [at, setAt] = useState(false);
    const [vs, setVs] = useState(false);
    const [sr, setSr] = useState(false);
    const [as, setAs] = useState(false);
    const [limitation, setLimit] = useState(500);
    const [dailyLimit, setDailyLimit] = useState(150);
    const [businessModels, setBusinessModels] = useState([]);

    const [confirmationModal, setConfirmationModal] = useState(false)

    const [optionModels, setOptionModels] = useState([]);
    const [selectedDuration, setSelectedDuration] = useState(0);
    const [errorMsg, setErrorMsg] = useState(null);
    const [inputType, setInputType] = useState('text')
    const [select] = useStore('Select','clients')
    // const [reseter, setReseter] = useState(false);

    const {stateObject} = props;
    
    const userRegistrationInfoObject = {
        username,
        password,
        mail,
        company,
        businessModelId,
        at,
        vs,
        sr,
        as,
        limitation,
        dailyLimit,
        businessModels,
        selectedDuration
    }
    const dismissModal = () =>{
        stateObject.setToRegister(false);
        document.body.style.overflowY = "auto";
    }

    useEffect(() => {
        let randomstring = Math.random().toString(36).slice(-10);
        setPassword(randomstring + '#')
        setRepeatPass(randomstring + '#')
    }, [])

    const submitRegister = () => {
        let data = {};
        if(!username || username === '' || !password || !repeatPass || !mail || mail === ''){
            setErrorMsg('Required Fields Are Not Filled')
            return
        }
        data.username = username;
        data.password = password;
        data.confirm_password = repeatPass;
        data.email = mail;
        if(company && company !== ''){
            data.company = company;
        }
        if((at || vs || sr || as) && parseInt(businessModelId)!==0){
            data.solutions = [];
                // console.log('tuka paga')
                if(at) {
                    // console.log('at')
                    // console.log(at)
                    let obj = {};
                    obj.solution_id = 1;
                    if(parseInt(limitation) > 0) {
                        
                        obj.limitation = parseInt(limitation);
                    } else {
                        obj.limitation = parseInt(0);
                    }
                    if(parseInt(dailyLimit) > 0){
                        obj.limit_by_day = parseInt(dailyLimit);
                    }else {
                        
                        obj.limit_by_day = parseInt(0);
                    }
                    obj.business_model_id = parseInt(businessModelId);
                    data.solutions.push(obj);
                }
                if(vs) {
                    // console.log('vs')
                    // console.log(vs)
                    let obj = {};
                    obj.solution_id = 2;
                    if(parseInt(limitation) > 0) {
                        
                        obj.limitation = parseInt(limitation);
                    } else {
                        obj.limitation = parseInt(0);
                    }
                    if(parseInt(dailyLimit) > 0){
                        obj.limit_by_day = parseInt(dailyLimit);
                    }else {
                        
                        obj.limit_by_day = parseInt(0);
                    }
                    obj.business_model_id = parseInt(businessModelId);
                    data.solutions.push(obj);
                }
                if(sr) {
                    // console.log('sr')
                    // console.log(sr)
                    let obj = {};
                    obj.solution_id = 3;
                    if(parseInt(limitation) > 0) {
                        
                        obj.limitation = parseInt(limitation);
                    } else {
                        obj.limitation = parseInt(0);
                    }
                    if(parseInt(dailyLimit) > 0){
                        obj.limit_by_day = parseInt(dailyLimit);
                    }else {
                        
                        obj.limit_by_day = parseInt(0);
                    }
                    obj.business_model_id = parseInt(businessModelId);
                    data.solutions.push(obj);
                }
                if(as) {
                    // console.log('as')
                    // console.log(as)
                    let obj = {};
                    obj.solution_id = 4;
                    if(parseInt(limitation) > 0) {
                        
                        obj.limitation = parseInt(limitation);
                    } else {
                        obj.limitation = parseInt(0);
                    }
                    if(parseInt(dailyLimit) > 0){
                        obj.limit_by_day = parseInt(dailyLimit);
                    }else {
                        
                        obj.limit_by_day = parseInt(0);
                    }
                    obj.business_model_id = parseInt(businessModelId);
                    data.solutions.push(obj);
                }
        }
        // console.log(data)
        stateObject.reseter?stateObject.setReseter(false):stateObject.setReseter(true);
        register(data, select).then(res=>{
            if (res.responseStatus === 200){
                stateObject.reseter?stateObject.setReseter(false):stateObject.setReseter(true);
                dismissModal();
            } else {
                // console.log(res)
                setErrorMsg(res.detail)
            }
        });

    }

   

    useEffect(()=>{
        getAllBusinessModels(select).then(res=>{
            // console.log(res)
            if(res.responseStatus === 200)
                setBusinessModels(res.result);
        })
    },[])
    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";
    },[errorMsg])

    useEffect(()=>{
        if(businessModels.length > 0){

            let options = businessModels.map(model => 
                    <option key={model.bm_id} value={model.bm_id}>{model.name} </option>
                )
            options.unshift((<option key={0} value={0}> No Business Model</option>))
            setOptionModels(options);
            
        }
        
    },[businessModels])

    const convertUnlimited = () => {
        if(parseInt(dailyLimit) > 0){
            setDailyLimit(dailyLimit);
        }else {
            setDailyLimit('Unlimited')
        }

        if(parseInt(limitation) > 0) {
                        
           setLimit(limitation);
        } else {
            setLimit('Unlimited')
        }
    }

    const displayPass = () => {
        if(inputType === 'password') {
            setInputType('text')
        } else {
            setInputType('password')
        }
    }

    useEffect(()=>{
        if (businessModels.length > 0) {
            businessModels.forEach( model=>{
                if(parseInt(model.bm_id) === parseInt(businessModelId)){
                    setSelectedDuration(model.duration_in_days);
                }
                // console.log(model)
            })
        }
    },[businessModels, businessModelId])

    return ReactDOM.createPortal(
        <div onClick={() => dismissModal} className="ui dimmer modals visible active">
            <div onClick={e=>e.stopPropagation()} className="ui standard modal visible active modal-class upload-new register" style={{minWidth:"80%"}}>
                <div className="content direction-row">
                    <div className="column-direction half-width">
                        <div className="register-title">Sign Up</div>
                        <div className="register-subtitle">Create and register account</div>
                        <div className="input-div">
                            <label htmlFor="username">Username (3-50 Characters)</label>
                            <input
                            value={username}
                            onChange={e=>setUsername(e.target.value)} 
                            className="whole-input" 
                            id="username" name="username" 
                            type="text" placeholder="Client Name"
                            required />
                        </div>
                        <div className="input-div direction-row">
                            <div className="pass-div">
                                <label htmlFor="password">Password (Min 6 characters)</label>
                                <input 
                                value={password}
                                onChange={e=>setPassword(e.target.value)}
                                className="whole-input-pass" 
                                id="password" name="password" 
                                type={inputType} placeholder="password123"
                                required />
                                <button className="show-pass-btn" onClick={displayPass}><i className="eye icon"></i></button>
                            </div>
                            <div className="pass-div">
                                <label htmlFor="repeat-password">repeat-password</label>
                                <input 
                                value={repeatPass}
                                onChange={e=>setRepeatPass(e.target.value)}
                                className="whole-input-pass" 
                                id="repeat-password" name="password" 
                                type={inputType} placeholder="password123"
                                required />
                                <button className="show-pass-btn" onClick={displayPass}><i className="eye icon"></i></button>
                            </div>
                        </div>
                        <div className="input-div column-direction">
                            <label htmlFor="e-mail"> e-mail </label>
                            <input
                            value={mail}
                            onChange={e=>setMail(e.target.value)} 
                            className="whole-input" 
                            id="e-mail" name="e-mail" 
                            type="email" placeholder="client@email.com"
                            required />
                        </div>
                        <div className="input-div column-direction">
                            <label htmlFor="company"> company </label>
                            <input
                            value={company}
                            onChange={e=>setCompany(e.target.value)} 
                            className="whole-input" 
                            id="company" name="company" 
                            type="text" placeholder="Company Name" />
                        </div>
                    </div>


                {/* ////////////////////////////////////////////////////////////////////// */}


                    <div className="column-direction half-width">
                        <div className="register-title">Set Solutions</div>
                        <div className="register-subtitle">Set up the solutions and business model</div>
                        <div className="input-div">
                            <label htmlFor="business_model">Business Model</label>
                            <select 
                            value={businessModelId}
                            onChange={e=>setBusinessModelId(e.target.value)}
                            className="whole-input" 
                            id="business_model" name="business_model" 
                            type="text" placeholder="Client Name" >
                                {
                                    optionModels.length > 0?
                                    optionModels : <option>Wait For It...</option>
                                }
                                
                            </select>
                        </div>

                        <div className="input-div column-direction" style={{maxWidth:"90%"}}>
                            <label htmlFor="duration">Duration In Days</label>
                            <input 
                                disabled
                                value={selectedDuration}
                                onChange={e=>setSelectedDuration(e.target.value)}
                                name="duration"
                                id="duration"
                                type="number" />
                        </div>

                        <div className="input-div column-direction">
                            <div className="direction-row check">
                                <input 
                                value={at}
                                onChange={e=>setAt(e.target.checked)}
                                name="at"
                                id="at"
                                type="checkbox" />
                                <label className="check-label" htmlFor="at">Automatic Tagging</label>
                            </div>
                            <div className="direction-row check">
                                <input 
                                value={vs}
                                onChange={e=>setVs(e.target.checked)}
                                name="vs"
                                id="vs"
                                type="checkbox" />
                                <label className="check-label" htmlFor="vs">Visual Search</label>
                            </div>
                            <div className="direction-row check">
                                <input 
                                value={sr}
                                onChange={e=>setSr(e.target.checked)}
                                name="sr"
                                id="sr"
                                type="checkbox" />
                                <label className="check-label" htmlFor="sr">Similar Recommendations</label>
                            </div>
                            <div className="direction-row check">
                                <input 
                                value={as}
                                onChange={e=>setAs(e.target.checked)}
                                name="as"
                                id="as"
                                type="checkbox"/>
                                <label className="check-label" htmlFor="as">Auto Rescale</label>
                            </div>
                        </div>

                        <div className="input-div direction-row">
                            <div className="pass-div">
                                <label htmlFor="limitation">Image Limitations</label>
                                <input 
                                value={limitation}
                                onChange={e=>setLimit(e.target.value)}
                                className="whole-input" 
                                id="limitation" name="limitation" 
                                list="list1"
                                type="text" />
                                <datalist id="list1">
                                    <option value="Unlimited"/>
                                </datalist>
                            </div>
                            <div className="pass-div">
                                <label htmlFor="limit_by_day">Daily Image Limit</label>
                                <input 
                                value={dailyLimit}
                                onChange={e=>setDailyLimit(e.target.value)}
                                className="whole-input" 
                                id="limit_by_day" name="limit_by_day" 
                                list="list2"
                                type="text"/>
                                <datalist id="list2">
                                    <option value="Unlimited"/>
                                </datalist>
                            </div>
                        </div>
                        
                    </div>
                </div>
            
                <div className="actions">
                    <button onClick={dismissModal} className="button-all">Cancel And Exit</button>
                    <button onClick={() => {setConfirmationModal(true); convertUnlimited()}} className="button-create">Create And Proceed</button>
                </div>
            </div>

            {
                errorMsg? <ErrorModal msg={errorMsg} exitFunc={()=>setErrorMsg(null)} /> 
                : null
            }
            {
                confirmationModal ? <CreateComfirmModal closeModalBig={dismissModal}  closeModalSmall={setConfirmationModal} funk={submitRegister} userRegistrationInfoObject={userRegistrationInfoObject} errorMsg={errorMsg} setErrorMsg={setErrorMsg}/> : null
            }
        </div>, document.getElementById('modal')
    )
}

export default Register