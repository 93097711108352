import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useStore} from 'react-context-hook';
import {getAllBusinessModels, addBusinessModel} from "../../../endpoints/businessModel";
import YesNoModal from '../UtilityModals/YesNoModal'
import {mapModels} from "./functions";


const AllModels = (props) => {
    const {stateObject} = props;

    const [allModels, setAllModels] = useState([]);
    const [nextModelId, setNextModelId] = useState(null);
    const [nextModelName, setNextModelName] = useState('');
    const [nextModelDuration, setNextModelDuration] = useState(14);
    const [reseter, setReseter] = useState(false)
    const [bizYesNo, setBizYesNo] = useState(false)
    const [select] = useStore('Select','clients')

    
    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";

        getAllBusinessModels(select).then(res=>{
            if(res.responseStatus === 200 && res.result){
                setAllModels(res.result)
            }
            // console.log(res)
        })
    }, [reseter])
    const exitModal = () => {
        window.scrollTo(0, 0);
        document.body.style.overflowY = "auto";
        stateObject.setShowAllModels(false)
    }

    const createModel = () => {
        let data = {};
        data.name = nextModelName;
        data.duration_in_days = parseInt(nextModelDuration);
        addBusinessModel(data, select).then(res=>{
            // console.log(res)
            reseter?setReseter(false):setReseter(true)
        })
        setNextModelName('')
    }


    return ReactDOM.createPortal(
    <div onClick={exitModal} className="ui dimmer modals visible active">
        <div onClick={e=>e.stopPropagation()} className="ui standard modal visible active modal-class" style={{minWidth:"30%"}}>
            <div className="content models">
                <div className="modal-title">Business Models</div>
                <div className="table-holder">
                <table className="admin-table model">
                    <thead>
                        <tr >
                            <th className="t-head" >Business Model ID</th>
                            <th className="t-head" >Business Model Name</th>
                            <th className="t-head" >Duration In Days </th>
                        </tr>
                        
                    </thead>
                    
                    <tbody style={{horizontalAlign:"middle"}}>
                    <hr className="thead-row"></hr>
                    {   
                    allModels && allModels.length>0?
                        mapModels(allModels, nextModelId, setNextModelId)
                        : null
                    }
                    {
                        nextModelId? 
                        <tr className="model-row">
                            <td className="td-first-round-edges td">{nextModelId + 1}</td>
                            <td className="td" >
                                <input 
                                className="bmId-input"
                                value={nextModelName}
                                onChange={e=>setNextModelName(e.target.value)}
                            /></td>
                            <td className="td td-last-round-edges" >
                                <div className="direction-row">
                                    <input
                                    className="bmId-input"
                                    value={nextModelDuration}
                                    onChange={e=>setNextModelDuration(e.target.value)}
                                    /> 
                                    <div onClick={setBizYesNo} className="direction-row"> <i className = "save icon"></i></div>
                                </div>
                            </td>
                        </tr>
                        :null
                    }
                    </tbody>
                </table>
             
                </div>
   
            </div>
            {
                bizYesNo ? <YesNoModal closeModal={setBizYesNo} funk={createModel} msg={'Are you sure you want to add new Business Model.'} confirmationMsg={'New Business Model was added'} /> : null
            }
            {/* <div className="actions">
                <button className="button-create">fashion-taxonomy.pixyle.ai (a183a2b1-e29f-4442-a866-fb94cba460c0)</button>
                <button className="button-all">heehee</button>
            </div> */}
        </div>
    </div>, document.getElementById('modal')
    )
}
export default AllModels;