import React, {useState, useEffect} from "react";
import {useStore} from "react-context-hook";
import InputField from "../InputField/InputField";
import {updateSolution} from "../../endpoints/solutions";
import UpdateModel from "../Modals/BusinessModels/UpdateModel";
import AddSolution from "../Modals/AddSolutions/AddSolution";
import analyticsIcon from "../../assets/chart-bar.svg";
import EmptyRow from "./EmtySpaceRow";
import Analytics from "../Modals/Analytics/Analytics";
import IdFilesModal from "../IdFiles/AddIdFilesModal";

const PrimaryRow = (props) => {
    const {user} = props;
    const [limitation, setLimit] = useState(null);
    const [dailyLimit, setDailyLimit] = useState(null);

    const [reseter, setReseter] = useStore('reseter',false);
    const [, setFailMsg] = useStore('fail', null);

    const [showModels, setShowModels] = useState(false);
    const [addSolutions, setAddSolutions] = useState(false);

    const [showAnalytics, setShowAnalytics] = useState(false);
    const [openIdFilesModal, setOpenIdFilesModal] = useState(false)
    const [select] = useStore('Select','clients')

    const stateObject = {
        showModels,
        setShowModels,
        addSolutions,
        setAddSolutions,
        showAnalytics,
        setShowAnalytics
    }

    // const [, setShowModels] = useStore('showModels', false)
    const convertTime = (timeDate) => {
        if(user.solution_name !== null) {
        let converted = Date.parse(timeDate);
        // let num = user.days_until_expiration + 1000 * 60 * 60 * 24 * 3
        // let wet = converted - num
        let day = new Date(converted).getDate();
        let month = new Date(converted).getMonth();
        let year = new Date(converted).getFullYear();
        month++;
        return " " + day +"." + month + "." + year;
        } else {
            return null
        }
    }

    const expiresAt = (reactivated_at, duration_in_days) => {

        // Date.prototype.addDays = function (days) {
        //     var date = new Date(this.valueOf());
        //     date.setDate(date.getDate() + days);
        //     return date;
        // }

        if(user.solution_name !== null) {
            // let today = new Date()
            // let expiresOn = today.addDays(days_until_expiration)
            // let day = new Date(expiresOn).getDate();
            // let month = new Date(expiresOn).getMonth();
            // let year = new Date(expiresOn).getFullYear();
            // month ++;
            // return ` ${day}.${month}.${year}`
            


            
            let converted = Date.parse(reactivated_at);
            let expiresAtConverted =  1000 * 60 * 60 * 24 * duration_in_days
            let wet = converted + expiresAtConverted
            let day = new Date(wet).getDate();
            let month = new Date(wet).getMonth();
            let year = new Date(wet).getFullYear();
            month++;
            return " " + day +"." + month + "." + year;
            // } else if (duration_in_days < 0) {
            //     let converted = Date.parse(reactivated_at);
            //     let expiresAtConverted =  1000 * 60 * 60 * 24 * duration_in_days
            //     let wet = converted - expiresAtConverted
            //     let day = new Date(wet).getDate();
            //     let month = new Date(wet).getMonth();
            //     let year = new Date(wet).getFullYear();
            // month++;
            // return " " + day +"." + month + "." + year;
            } else {
                return null
            }
    }
    const getSolution = (solName) => {
        // console.log(solName)
        if(solName !== null) {
            let arr = solName.split("_");
            return arr[0] + " " +  arr[1]
        } return "Not Found"
    }
    const save = (val, attribute) => {
        let solData = {};
        solData.client_id = user.client_id;
        solData.cs_id = user.cs_id;
        solData[attribute] = parseInt(val);
        let data = [solData];
        return updateSolution(data, select);
    }
    const reactivateSol = (active) => {
        let toActive = true;
        if(active === "false"){
            toActive = false;
        }
        let solData = {}
        solData.client_id = user.client_id;
        solData.cs_id = user.cs_id;
        solData['active'] = toActive;
        let data = [solData];
        // console.log(data)
        updateSolution(data, select).then((res)=>{
            if(res.error_count > 0 ){
                setFailMsg(res.errors[0].detail);
            } else {
                reseter ? setReseter(false) : setReseter(true)
            }    
        })
    }
    const selectModel = (bm_id) => {
        // console.log(bm_id);
        let solData = {}
        solData.client_id = user.client_id;
        solData.cs_id = user.cs_id;
        solData['business_model_id'] = bm_id;
        let data = [solData];
        updateSolution(data, select).then((res)=>{
            if(res.error_count > 0 ){
                setFailMsg(res.errors[0].detail);
            } else {
                reseter ? setReseter(false) : setReseter(true)
                setShowModels(false);
                document.body.style.overflowY = "auto";
            }    
        })
    }

    useEffect(() => {
        if(user) {
            setLimit(parseInt(user.limitation) && parseInt(user.limitation) > 0?  parseInt(user.limitation) : "Unlimited");
            setDailyLimit(user.limit_by_day && user.limit_by_day>0?  user.limit_by_day : "None");
            // setSolutionActive(user.solution_active)
        }
        // console.log(user)

    }, [user, limitation, dailyLimit, reseter])
    
    return (
        <>
        <EmptyRow />
        <tr className="primary-row">
            <td className={"td-first-round-edges td cursore"} onClick={() => user.solution_name ? setShowAnalytics(true) : setOpenIdFilesModal(true)}>{user.client_id}</td>
            <td className="td cursore" onClick={() => user.solution_name ? setShowAnalytics(true) : setOpenIdFilesModal(true)}>{user.username}</td>
            <td className="td crop fixed-width-mail cursore" onClick={() => user.solution_name ? setShowAnalytics(true) : setOpenIdFilesModal(true)}>{user.email}</td>
            <td className="td company cursore" onClick={() => user.solution_name ? setShowAnalytics(true) : setOpenIdFilesModal(true)}>{user.company}</td>
            <td className="td cursore" onClick={() => user.solution_name ? setShowAnalytics(true) : setOpenIdFilesModal(true)}>{user.days_until_expiration > 0 ? user.days_until_expiration : 'Expired'}</td>
            <td className="td transform fixed-width-mail"><div className="crop start cursore" onClick={() => user.solution_name ? setShowAnalytics(true) : setOpenIdFilesModal(true)}>{getSolution(user.solution_name)}</div> <i onClick={()=>setAddSolutions(true)} className="icon plus cursore"></i></td>
            <td className="td cursore" onClick={() => user.solution_name ? null : setOpenIdFilesModal(true)}>
            {user.solution_name? <InputField attributeValue={limitation} attribute="limitation" save={save} /> : null}
                
            </td>
            <td className="td cursore" onClick={() => user.solution_name ? null : setOpenIdFilesModal(true)}>
            {user.solution_name? <InputField attributeValue={dailyLimit} attribute="limit_by_day" save={save} /> : null}
                
            </td>
             <td className="td cursore" onClick={() => user.solution_name ? null : setOpenIdFilesModal(true)}>

            {user.solution_name ?
            <select 
            className="cursore"
            onChange={e=>reactivateSol(e.target.value)}
            value={user.solution_active}>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select> 
                : null
            }

            </td>
            
            
            <td className="td hide cursore" onClick={() => user.solution_name ? setShowAnalytics(true) : setOpenIdFilesModal(true)}>{expiresAt(user.reactivated_at, user.duration_in_days)}</td>
            <td className="td hide cursore" onClick={() => user.solution_name ? setShowAnalytics(true) : setOpenIdFilesModal(true)}>{convertTime(user.reactivated_at)}</td>
            <td className="td select cursore"><span onClick={() => user.solution_name ? setShowAnalytics(true) : setOpenIdFilesModal(true)}>{user.solution_name? user.business_model_name : 'Not Found'}</span> {user.solution_name? <i onClick={()=>setShowModels(true)} className = "pencil alternate icon cursore"></i> : null}</td>
            <td className={"td td-last-round-edges"} > 
            {user.solution_name? <img src={analyticsIcon} className="cursore svg-gray dash-icon" onClick={()=>setShowAnalytics(true)}  alt="analytics"/> : 
                    <i className='edit outline icon cursore'  onClick={()=>setOpenIdFilesModal(true)} style={{fontSize:"1.1em", }}/>
            }
            </td>
            
            {
                showModels? <UpdateModel stateObject={stateObject} selectModel={selectModel} username={user.username} /> : null
            }
            {
                addSolutions? <AddSolution user={user} stateObject={stateObject} /> : null
            }
            
        </tr>

            {
            showAnalytics?  <Analytics user={user} stateObject={stateObject} /> : null
            }
             {
            openIdFilesModal?  <IdFilesModal user={user} stateObject={stateObject} closeModal={setOpenIdFilesModal }/> : null
            }
        
        </>
    )
}
export default PrimaryRow;