import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useStore} from "react-context-hook";
import {getAllBusinessModels} from "../../../endpoints/businessModel";
import {solutions, createClientSolution} from "./functions";
import ErrorModal from "../UtilityModals/ErrorModal";
import CreateComfirmModal from '../UtilityModals/CreateComfirmModal'

const AddSolution = (props) => {

    const {user, stateObject} = props;

    const [businessModels, setBusinessModels] = useState([]);
    const [selectedBusinessModel, setSelectedBusinessModel] = useState(0);
    const [selectedSol, setSelectedSol] = useState(0);
    const [optionSolutions, setOptionSolutions] = useState([]);
    const [optionModels, setOptionModels] = useState([]);
    const [selectedDuration, setSelectedDuration] = useState(0);
    const [limitation, setLimit] = useState(500);
    const [dailyLimit, setDailyLimit] = useState(150);
    const [errorMsg, setErrorMsg] = useState(null);
    const [select] = useStore('Select','clients')

    const [reseter, setReseter] = useStore('reseter',false);

    const [openCreateConfirmationModalm, setOpenCreateConfirmationModalm] = useState(false)

    const allState = {
        client_id: user.client_id,
        businessModels,
        selectedBusinessModel,
        selectedSol,
        optionSolutions,
        optionModels,
        selectedDuration,
        limitation,
        dailyLimit,
        errorMsg,
        reseter,
        setErrorMsg,
        setReseter,
        setAddSolutions: stateObject.setAddSolutions,
        select
    }

    
    const exit = () => {
        stateObject.setAddSolutions(false);
        document.body.style.overflowY = "auto";
    }


    const convertUnlimited = () => {
        if(parseInt(dailyLimit) > 0){
            setDailyLimit(dailyLimit);
        }else {
            setDailyLimit('Unlimited')
        }

        if(parseInt(limitation) > 0) {
                        
           setLimit(limitation);
        } else {
            setLimit('Unlimited')
        }
    }


    useEffect(()=>{
        getAllBusinessModels(select).then(res=>{
            // console.log(res)
            if(res.responseStatus === 200)
                setBusinessModels(res.result);
        })
    },[])
    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";
    },[errorMsg])
    useEffect(()=>{
        if(businessModels.length > 0){
            let options = businessModels.map(model => 
                    <option key={model.bm_id} value={model.bm_id}>{model.name} </option>
                )
            options.unshift((<option key={0} value={0}> No Business Model</option>))
            setOptionModels(options);   
        }   
    },[businessModels])
    useEffect(()=>{
        if(solutions.length > 0){
            let options = solutions.map(sol=>
                <option key={sol.solution_id} value={sol.solution_id}>{sol.solution_name} </option>
                )
            options.unshift((<option key={0} value={0}> No Solution Selected </option>))
            setOptionSolutions(options);
        }
    },[])
    useEffect(()=>{
        if (businessModels.length > 0) {
            businessModels.forEach( model=>{
                if(parseInt(model.bm_id) === parseInt(selectedBusinessModel)){
                    setSelectedDuration(model.duration_in_days);
                }
            })
        }
    },[businessModels, selectedBusinessModel])

    return ReactDOM.createPortal(
    <div onClick={exit} className="ui dimmer modals visible active">
        <div onClick={e=>e.stopPropagation()} className="ui standard modal visible active modal-class upload-new add-sol" style={{minWidth:"50%"}}>
            <div className="content column-direction paddings">
                <div className="register-title">Add Solution</div>
                <div className="register-subtitle">Adding Solution to User "{user.username}"</div>
                <div className="input-div column-direction">
                    <label htmlFor="solution_option">Solution</label>
                    <select 
                    value={selectedSol}
                    onChange={e=>setSelectedSol(e.target.value)}
                    className="whole-input" 
                    id="solution_option" name="solution_option" 
                    type="text" placeholder="Client Name" >
                        {
                            optionSolutions.length > 0 ?
                            optionSolutions :  <option>Wait for it</option>
                        }
                       
                        
                    </select>
                </div>

                <div className="input-div column-direction">
                    <label htmlFor="business_model">Business Model</label>
                    <select 
                    value={selectedBusinessModel}
                    onChange={e=>setSelectedBusinessModel(e.target.value)}
                    className="whole-input" 
                    id="business_model" name="business_model" 
                    type="text" placeholder="Client Name" >
                        {
                            optionModels.length > 0?
                            optionModels : <option>Wait For It...</option>
                        }
                        
                    </select>
                </div>

                <div className="input-div column-direction" style={{maxWidth:"90%"}}>
                    <label htmlFor="duration">Duration In Days</label>
                    <input 
                        disabled
                        value={selectedDuration}
                        onChange={e=>setSelectedDuration(e.target.value)}
                        className="duration-input"
                        name="duration"
                        id="duration"
                        type="number" />
                </div>

                <div className="input-div direction-row">
                    <div className="pass-div column-direction">
                        <label htmlFor="limitation">Image Limitations</label>
                        <input 
                        value={limitation}
                        onChange={e=>setLimit(e.target.value)}
                        className="whole-input" 
                        id="limitation" name="limitation"
                        list="listAddSolution" 
                        type="text" />
                        <datalist id="listAddSolution">
                            <option value="Unlimited"/>
                        </datalist>
                    </div>
                    <div className="pass-div column-direction">
                        <label htmlFor="limit_by_day">Daily Image Limit</label>
                        <input 
                        value={dailyLimit}
                        onChange={e=>setDailyLimit(e.target.value)}
                        className="whole-input" 
                        id="limit_by_day" name="limit_by_day"
                        list="listAddSolution2" 
                        type="text"/>
                        <datalist id="listAddSolution2">
                            <option value="Unlimited"/>
                        </datalist>
                    </div>
                </div>


            </div>
            <div className="actions">
                <button onClick={exit} className="button-all">Cancel And Exit</button>
                <button onClick={()=> {setOpenCreateConfirmationModalm(true); convertUnlimited();}} className="button-create">Create And Proceed</button>
            </div>
            {/* onClick={()=>{createClientSolution(allState); exit()}} */}
            {
                errorMsg? <ErrorModal msg={errorMsg} exitFunc={()=>setErrorMsg(null)} /> 
                : null
            }
            {
                openCreateConfirmationModalm ? <CreateComfirmModal addSolutionInfo={allState} closeModalSmall={setOpenCreateConfirmationModalm} funk={createClientSolution} errorMsg={errorMsg} setErrorMsg={setErrorMsg}/> : null
            }
        </div>
    </div>, document.getElementById("modal"))
}
export default AddSolution;