import React from 'react';
import moment from 'moment';
import {updateSolution} from "../../../endpoints/solutions";
import InputField from "../../InputField/InputField";
import atIcon from "../../../assets/autotag.svg";
import vsIcon from "../../../assets/visual_search.svg";
import srIcon from "../../../assets/similar_recommendations.svg";
import asIcon from "../../../assets/auto_rescale.svg"

export const setupClientsActivity = (result) => {
    let clientsActivities=[]
    //moment(activity.date).format('DD-MM-YYYY')
    result.client_activity.forEach(activity=>(
        clientsActivities.push({x:new Date(activity.date),y:activity.number_of_uploads})
    ))
    clientsActivities = clientsActivities.filter(activity=>
        (new Date().getTime() - activity.x.getTime())<(30 * 24 * 60 * 60 * 1000)
        )
    clientsActivities.sort((a,b)=>{
        var dateA=new Date(a.x),dateB=new Date(b.x);
        return dateA - dateB;
    })
    clientsActivities.forEach(activity=>
        activity.x = moment(new Date(activity.x)).format('DD-MM-YYYY'))
    return clientsActivities
}

export const setupFrequentHours = (result) => {
  let frequentH=[]
  //moment(activity.date).format('DD-MM-YYYY')
  result.frequent_upload_hours.forEach(hour=>(
    frequentH.push({x:hour.hour_of_day,y:parseInt(hour.datasets_uploaded)})
  ))

  frequentH.sort((a,b)=>{
      var hourA=a.x,hourB=b.x;
      return hourA - hourB;
  })
  frequentH.forEach(hour=>{
    var hours= hour.x
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hour.x = hours+ampm
  })

  return frequentH
}

export const progressTheme = {
    error: {
      trailColor: "rgba(142, 154, 175, 0.2)",
      color: "red",
      symbol:""
    },
    default: {
      trailColor: "rgba(142, 154, 175, 0.2)",
      color: "red",
      symbol:""
    },
    active: {
      trailColor: "rgba(142, 154, 175, 0.2)",
      color: "red",
      symbol:""
    },
    success: {
      trailColor: "rgba(142, 154, 175, 0.2)",
      color: "#00BFB2",
      symbol:""
    }
  }

const getSolution = (solName) => {
    if (typeof solName === "string"){
      let arr = solName.split("_");
      let returned = '';
      for(let i = 0; i<arr.length; i++)
        returned = returned + " " + arr[i]
      return returned;
    }
    return solName;
}

const getRemovedDatasets = (keyVal, key) => {
  if (typeof keyVal === "string") {
    let arr = keyVal.split(',');
    return arr[key];
  }
  return null
}
export const getActiveSolutions = ( analyticsState) => {
  return analyticsState.analytics.map(obj =>{
    let iconClass;
    if(obj.abbreviation === 'at'){
      iconClass=atIcon;
    }
    else if(obj.abbreviation === 'vs'){
      iconClass=vsIcon;
    }
    else if(obj.abbreviation === 'sr'){
      iconClass=srIcon;
    }
    else if(obj.abbreviation === 'as'){
      iconClass=asIcon;
    }
    return (
        <button key={obj.solution_id} onClick={()=>analyticsState.setSelectedSol(obj.solution_id)} className={analyticsState.selectedSol === obj.solution_id ? `button-solution ${obj.abbreviation} selected transform cursore`: `button-solution ${obj.abbreviation} transform cursore`}>
          <img src={iconClass} alt="sol icon" className="svg-gray"></img>
          <div className="text">{getSolution(obj.solution_name)}</div> 
        </button>
    )
  })
}

export const analyticsInfo = (analytics, analyticsState) => {
  return analytics.map(analytic => {
    if(analytic.solution_id === analyticsState.selectedSol){
      return Object.keys(analytic).map((analyticKey, index)=>{
        if (analytic[analyticKey] === null || analytic[analyticKey] === undefined){
          return null;
        }
        else if (typeof analytic[analyticKey] === "boolean"){
          let boolValue;
          if (analyticsState.selectedSol === 1) {
            boolValue = analyticsState.atUpdate[analyticKey]
          } else if (analyticsState.selectedSol === 2) {
            boolValue = analyticsState.vsUpdate[analyticKey]
          } else if (analyticsState.selectedSol === 3) {
            boolValue = analyticsState.srUpdate[analyticKey]
          } else if (analyticsState.selectedSol === 4) {
            boolValue = analyticsState.asUpdate[analyticKey]
          }
          return (
            <div className="analytics-row direction-row" key={index}>
              <p className="key">{getSolution(analyticKey)}</p>
               : 
               <select 
                className="cursore"
                onChange={e=>{
                  // console.log('changing')
                  // console.log(analyticsState.selectedSol)
                  if (analyticsState.selectedSol === 1) {
                    let updateObj = analyticsState.atUpdate;
                    if (e.target.value == 'true')
                     updateObj[analyticKey] = true;
                    else
                    updateObj[analyticKey] = false;
                    analyticsState.setAtUpdate({...updateObj});
                  } else if (analyticsState.selectedSol === 2) {
                    let updateObj = analyticsState.vsUpdate;
                    if (e.target.value == 'true')
                      updateObj[analyticKey] = true;
                    else
                      updateObj[analyticKey] = false;
                    analyticsState.setVsUpdate(updateObj)
                  } else if (analyticsState.selectedSol === 3) {
                    let updateObj = analyticsState.srUpdate;
                    if (e.target.value == 'true')
                      updateObj[analyticKey] = true;
                    else
                      updateObj[analyticKey] = false;
                    analyticsState.setSrUpdate(updateObj)
                  } else if (analyticsState.selectedSol === 4) {
                    let updateObj = analyticsState.asUpdate;
                    if (e.target.value == 'true')
                      updateObj[analyticKey] = true;
                    else
                      updateObj[analyticKey] = false;
                    analyticsState.setAsUpdate(updateObj)
                  }
                  
                }}
                value={boolValue}>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
                </select> 
            </div>
          )
        }
        else if(analyticKey === "removed_datasets_products"){
          return (
            <>
              <div className="analytics-row direction-row" key={`${index}_1`}>
                Removed Datasets : {getRemovedDatasets(analytic[analyticKey], 0)} 
              </div>
              <div className="analytics-row direction-row" key={`${index}_2`}>
                Removed Products From Datasets : {getRemovedDatasets(analytic[analyticKey], 1)} 
              </div>
            </>
          )
        }
        else if (analyticKey === "limitation" || analyticKey === "limit_by_day") {
          return (
            <div className="analytics-row direction-row" key={index}>
            <p className="key">
              {getSolution(analyticKey)}
            </p>  :
            {
              <InputField attributeValue={analytic[analyticKey]} attribute={analyticKey} save={saveInputField} analyticsState={analyticsState} />
            }
            
          </div>
            
          )
        }
        else if (analyticKey === 'business_model_id') {
          return (
            <div className="analytics-row direction-row" key={index}>
               <p className="key">
                {getSolution(analyticKey)}
              </p>  : {getSolution(analytic[analyticKey])}
                <i onClick={()=>analyticsState.setShowModels(true)} className = "pencil alternate icon cursore"></i> 
              </div>
            
          )
        }
        else if(analyticKey === 'days_until_expiration') {
          return (
            <div className="analytics-row direction-row" key={index}>
              <p className="key">
                {getSolution(analyticKey)}
              </p>  : {analytic[analyticKey] < 0 ? "Expired" : analytic[analyticKey]}
              
            </div>
          )
        }
        return (
          <div className="analytics-row direction-row" key={index}>
            <p className="key">
              {getSolution(analyticKey)}
            </p>  : {getSolution(analytic[analyticKey])}
            
          </div>
        )
      })
    }
    return null;
  })
}

//Will be used for dailyLimit and limitation
export const saveInputField = (attrValue, attr, analyticsState) => {
  // console.log('savvingg')
  // console.log(analyticsState)
  let updateObj = analyticsState.atUpdate;
  updateObj[attr] = parseInt(attrValue);
 if(analyticsState.selectedSol === 1) {
  let updateObj = analyticsState.atUpdate;
  updateObj[attr] = parseInt(attrValue);
  analyticsState.setAtUpdate(updateObj)
 }
 else if (analyticsState.selectedSol === 2) {
  let updateObj = analyticsState.vsUpdate;
  updateObj[attr] = parseInt(attrValue);
  analyticsState.setVsUpdate(updateObj);
  // console.log(updateObj, 'vss!!!!!!!!')
} 
else if (analyticsState.selectedSol === 3) {
  let updateObj = analyticsState.srUpdate;
  updateObj[attr] = parseInt(attrValue);
  analyticsState.setSrUpdate(updateObj);
  // console.log(updateObj, 'srrrrr!!!!!!!!')
}
else if (analyticsState.selectedSol === 4) {
  let updateObj = analyticsState.asUpdate;
  updateObj[attr] = parseInt(attrValue);
  analyticsState.setAsUpdate(updateObj);
  // console.log(updateObj, 'rescalee!!!!!!!!')
}
}


export const setUpdateState = (analytics, analyticsState) => {
  analytics.forEach(obj => {
    let updateObj = {};
    updateObj.client_id = obj.client_id;
    updateObj.cs_id = obj.cs_id;
    updateObj.limitation = obj.limitation;
    updateObj.limit_by_day = obj.limit_by_day;
    updateObj.business_model_id = obj.business_model_id;
    updateObj.active = obj.solution_active;
    // updateObj.user_active = obj.activated;
    if (obj.solution_id === 1) {
      analyticsState.setAtUpdate(updateObj);
      // console.log(updateObj, 'autotag!!!!!!!!')
    }
    else if (obj.solution_id === 2) {
      analyticsState.setVsUpdate(updateObj);
      // console.log(updateObj, 'vss!!!!!!!!')
    } 
    else if (obj.solution_id === 3) {
      analyticsState.setSrUpdate(updateObj);
      // console.log(updateObj, 'srrrrr!!!!!!!!')
    }
    else if (obj.solution_id === 4) {
      analyticsState.setAsUpdate(updateObj);
      // console.log(updateObj, 'rescalee!!!!!!!!')
    }
  })
}

export const bulkUpdateUserSolutions = (analyticsState, select) => {
  let sendData = [];
  if(Object.keys(analyticsState.atUpdate).length > 0){
    sendData.push(analyticsState.atUpdate);
  }
  if(Object.keys(analyticsState.vsUpdate).length > 0){
    sendData.push(analyticsState.vsUpdate);
  }
  if(Object.keys(analyticsState.srUpdate).length > 0){
    sendData.push(analyticsState.srUpdate);
  }
  if(Object.keys(analyticsState.asUpdate).length > 0){
    sendData.push(analyticsState.asUpdate);
  }
  // console.log(sendData);
  updateSolution(sendData, select).then(res => {
    // console.log(res)
  })
}

export const updateUserSolution = (analyticsState, select) => {
  let sendData = [];

  if(Object.keys(analyticsState.atUpdate).length > 0 && analyticsState.selectedSol === 1){
    sendData.push(analyticsState.atUpdate);
  }
  if(Object.keys(analyticsState.vsUpdate).length > 0 && analyticsState.selectedSol === 2){
    sendData.push(analyticsState.vsUpdate);
  }
  if(Object.keys(analyticsState.srUpdate).length > 0 && analyticsState.selectedSol === 3){
    sendData.push(analyticsState.srUpdate);
  }
  if(Object.keys(analyticsState.asUpdate).length > 0 && analyticsState.selectedSol === 4){
    sendData.push(analyticsState.asUpdate);
  }
  // console.log(sendData);
  updateSolution(sendData, select).then(res => {
    // console.log(res)
  })
}

export const reactivateSolution = (analyticsState, select) => {
  let clientSolution = analyticsState.analytics.filter(cs => cs.solution_id === analyticsState.selectedSol)[0];
  let sendDataObj = {};
  sendDataObj.cs_id = clientSolution.cs_id;
  sendDataObj.client_id = clientSolution.client_id;
  sendDataObj.active = true;
  let sendData = [sendDataObj];
  updateSolution(sendData, select).then(res => {
    // console.log(res)
  })
}