import React, {useEffect} from 'react';
import ReactDOM from 'react-dom'
import {SendMail} from '../../../endpoints/SendMail';
import { useStore } from "react-context-hook";
import {useHistory} from 'react-router-dom';

// Modal props expected:
// backTo prop, a string, showing the rout when hitting cancel or outside the model
// title prop, a string, showing the text in the modal
// accept prop, a string, showing the text on the "accept" button, or the confirmative scenario
// decline prop, a string, showing the text on the "decline" button, or the negative scenario
// acceptHandler prop, a function, doing the action needed for this modal
//add getBack prop as a function, so we dont reset shit the whole time



const ModalPixyle = ({closeModal, user, msg, closeBigModal = null, msgLink}) => {
    // let history = useHistory();
    // const [, setModalIsOn] = useStore("modalIsOn", false);
    // const [solutionShortName,] = useStore("solutionShortName","")

    const goBack = () => {
        document.body.style.overflowY = "auto";
        closeModal(false)
        if(closeBigModal) {
            closeBigModal(false)
        }
        
    }

    useEffect(()=>{
        //scroll to the top and prevent background scrolling
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden"; 
    },[])

    return ReactDOM.createPortal(
        <div onClick={() => goBack()} className="ui dimmer modals visible active">
            <div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active modal-class basicc" style={msgLink ? {minWidth:"65%"}: {minWidth: "35%"}}>
                <div className="content modal-wrapper wrapper-override" style={{padding:"0px"}}>
                    <div className="modal-delete-field" style={{padding:"2rem"}}>
                        <div><h3 className="modal-h3">{msg}</h3></div>

                        {msgLink ?<div><a href={`${msgLink}`} target="_blank" > {msgLink}</a></div> : null}
                    
                        <div className="modal-delete-buttons">
                            <button onClick={() => goBack()} className="button-all YesNo">Ok</button>
                            {/* <button onClick={()=> {SendMail(user.client_id); goBack()}} className="button-login YesNo">Yes</button> */}
                        </div> 
                    </div>
                </div>
            </div>
        </div>, document.getElementById("modal")

    )
}

export default ModalPixyle;