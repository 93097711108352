import React, {useState} from "react";
import SearchUser from "./SearchUser"
const SecondaryNavbar = (props) => { 
    const {stateObject, changeToggle} = props;
    return (
        <div className="navbar-menu-dashboard home">
            <div className="ui secondary menu">
                <SearchUser stateObject={stateObject} />
                <div className="right menu">
                    <div>
                    <button className="button-all" onClick={changeToggle}>Show All</button>
                    </div>
                    <div >
                        <button className="button-all" onClick={()=>stateObject.setShowAllModels(true)}>See All Business Models</button>
                    </div>
                    <div >
                        <button className="button-create" onClick={()=>stateObject.setToRegister(true)}>Sign Up New User</button>
                    </div>
                </div>
               
            </div>
           
        </div>
    )
}
export default SecondaryNavbar;