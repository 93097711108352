import React from "react";
import { withStore } from 'react-context-hook';
import "./sass/main.scss";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {getToken} from "./endpoints/validation";

import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import TopNavbar from "./components/TopNavbar/TopNavbar";

function App() {
  return (
    <Router>
      {getToken()?(<TopNavbar />) : null}
      <Switch>
        <Route 
          exact path = "/"
          render ={()=>(
            getToken()?
            <Dashboard />
            : <Redirect to="/login" />
          )}
        />
        <Route
         exact path = "/login"
         render={()=>(
           getToken()?
           <Redirect to="/" />
           : <Login />
         )}
        />
        <Route render={() => <Redirect to={{pathname: "/"}} />} />
      </Switch>
    </Router>
  );
}

export default withStore(App);
