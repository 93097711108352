import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useStore} from 'react-context-hook';
import {getAnalytics} from "../../../endpoints/analytics";
import {SendMail} from '../../../endpoints/SendMail';
import ClientActivity from "./ClientActivity";
import FrequentHours from "./FrequentHours";
import UpdateModel from "../BusinessModels/UpdateModel";
import CalendarModal from '../UtilityModals/CalendarModal'
import YesNoModal from '../UtilityModals/YesNoModal'
import ConfirmationModal from '../UtilityModals/ConfirmationModal'
import IdFilesModal from '../../IdFiles/AddIdFilesModal'

import {setupClientsActivity, 
    setupFrequentHours, 
    getActiveSolutions, 
    analyticsInfo, 
    setUpdateState, 
    reactivateSolution,
    updateUserSolution,
    bulkUpdateUserSolutions} from "./functions";


const AnalyticsModal = (props) => {

    const {stateObject, user} = props;

    const [analytics,setAnalytics] = useState({})
    const [selectedSol, setSelectedSol] = useState(1);
    const [clientsActivity,setClientsActivity]=useStore("clientsActivity",[]);
    const [frequentHours,setFrequentHours]=useStore("frequentHours",[]);
    const [reseter, setReseter] = useStore('reseter',false);
    const [openModal, setOpenModal] = useState(false)
    const [showModels, setShowModels] = useState(false);
    // const [selectedModel, selectModel] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [openCalendarModal, setOpenCalendarModal] = useState(false)
    const [OpenIdFilesModal, setOpenIdFilesModal] = useState(false)


    const [showOkModal, setShowOkModal] = useState(false)
    const [okModalMsg, setOkModalMsg] = useState('')
    //state to update client solution
    const [atUpdate, setAtUpdate] = useState({});
    const [vsUpdate, setVsUpdate] = useState({});
    const [srUpdate, setSrUpdate] = useState({});
    const [asUpdate, setAsUpdate] = useState({});
    const [select] = useStore('Select','clients')

    //mail for genereted link
    const [mailMsg, setMailMsg] = useState('')
    const [mailLink, setMailLink] = useState('')


// console.log(stateObject)
    const analyticsState = {
        analytics,
        selectedSol,
        setSelectedSol,
        atUpdate,
        setAtUpdate,
        vsUpdate, 
        setVsUpdate,
        srUpdate, 
        setSrUpdate,
        asUpdate, 
        setAsUpdate,
        showModels,
        setShowModels,
        
    }
   
   
    const setBM = (bm_id) => {
        if (selectedSol === 1) {
            let updateObj = atUpdate;
            updateObj.business_model_id = bm_id;
            setAtUpdate(updateObj);
        } else if (selectedSol === 2) {
            let updateObj = vsUpdate;
            updateObj.business_model_id = bm_id;
            setVsUpdate(updateObj);
        } else if (selectedSol === 3) {
            let updateObj = srUpdate;
            updateObj.business_model_id = bm_id;
            setSrUpdate(updateObj);
        } else if (selectedSol === 4) {
            let updateObj = asUpdate;
            updateObj.business_model_id = bm_id;
            setAsUpdate(updateObj);
        }
        setShowModels(false);
        document.body.style.overflowY = "hidden";
        
    }
    const dismissModal = () => {
        stateObject.setShowAnalytics(false);
        document.body.style.overflowY = "auto";
        reseter? setReseter(false):setReseter(true);
    }
    // console.log(user)
    const sendMailLink = () => {
        SendMail(analytics[0].client_id).then((res) => {
            setMailMsg(res.message)
            setMailLink(res.result)
        })
    }
    // console.log(mailMsg)
    // console.log(mailLink)
    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";

        if(user.client_id !== null && user.client_id !== undefined) {
            getAnalytics(user.client_id, select).then(res => {
                // console.log(res);
                if(res && res.responseStatus === 200) {
                    let clientsActivity = setupClientsActivity(res.result);
                    let freqHours = setupFrequentHours(res.result);
                    
                    setAnalytics(res.result.solutions_analytics);
                    // console.log(analytics)
                    setClientsActivity(clientsActivity);
                    setFrequentHours(freqHours);
                    setUpdateState(res.result.solutions_analytics, analyticsState)
                }
                
            })
        }
    }, [user])

    return ReactDOM.createPortal(
        <div onClick={dismissModal} className="ui dimmer modals visible active">
            <div onClick={e=>e.stopPropagation()} className="ui standard modal visible active modal-class upload-new register" style={{minWidth:"80%", minHeight:"90%"}}>
                <div className="content column-direction">
                    <div className="direction-row titles">
                    {
                        analytics && Object.keys(analytics).length>0? getActiveSolutions(analyticsState) : null
                    }
                    </div>
                    <div className="direction-row">
                        <div className="half-width sol-info-wrapper">
                            <div className = "column-direction sol-info">
                            {
                                analytics && Object.keys(analytics).length>0? analyticsInfo(analytics, analyticsState) : null
                            }
                            </div>
                        </div>
                        <div className="half-width column-direction">
                            {
                                clientsActivity ? <ClientActivity /> : null
                            }
                            {
                                frequentHours ? <FrequentHours  /> : null
                            }
                        </div>
                    </div>

                </div>
                <div className="actions">
                    
                    <button onClick={() => {setOpenCalendarModal(true)}} className="button-all-monthlyUsage">Monthly Usage</button>
                    <button onClick={() => {setOpenIdFilesModal(true)}} className="button-all-monthlyUsage">ID Files</button>
                    <button onClick={dismissModal} className="button-all">Cancel And Exit</button>
                    {/* <button onClick={()=> {setOpenModal(true); sendMailLink()}} className="button-all">Generate Link</button> */}
                    
                    <button onClick={()=>{setConfirmationModal(true)}} className="button-all">Reactivate</button>
                    <button onClick={()=>{bulkUpdateUserSolutions(analyticsState, select);setShowOkModal(true);setOkModalMsg('BuklUpdate completed successfully'); reseter === true ? setReseter(false) : setReseter(true)}} className="button-all">Bulk Update</button>
                    <button onClick={()=>{updateUserSolution(analyticsState, select);setShowOkModal(true);setOkModalMsg('Update completed successfully'); reseter === true ? setReseter(false) : setReseter(true) }} className="button-create">Update</button>
                    
                    
                    
                </div>
                {
                    showModels ? <UpdateModel stateObject={analyticsState} username = {analytics[0].username} selectModel = {setBM} upModal = {true}/> : null
                }
                
                {
                    confirmationModal ? <YesNoModal closeModal={setConfirmationModal} funk={reactivateSolution} user={analyticsState} msg={'Are you sure you want to reactivate this account.'} confirmationMsg={'This account has been reactivated.'}/> : null
                }
                {
                    openCalendarModal ? <CalendarModal closeModal={setOpenCalendarModal} analytics={analyticsState}/>  : null
                }
                {
                    showOkModal ? <ConfirmationModal msg={okModalMsg} closeModal={setShowOkModal} /> : null
                }
                {
                    openModal ? <ConfirmationModal msg={mailMsg} msgLink={mailLink} closeModal={setOpenModal}/> : null
                }
                {
                   OpenIdFilesModal ? <IdFilesModal closeModal={setOpenIdFilesModal} client_id = {analytics.client_id}  user={user}/>  : null
                }
            </div>
        </div>, document.getElementById('modal')
    )
}
export default AnalyticsModal;