import {baseURL} from './baseURL';
import {exit} from './validation';

const token = JSON.parse(localStorage.getItem('loginToken'));

export const addSolution = async (data, select) => {
    let responseStatus;
    let urlAnn = () => {
        if( select === 'clients') {
            return 'False'
        } else {
            return 'True'
        }
    }
    const annDb = urlAnn()
    try {
        return await fetch(`${baseURL}/users/new/solutions?annotation_db=${annDb}`, {
            method: "POST",
            headers: new Headers({
                "Authorization": `Bearer ${token.access_token}`,
                "Content-Type": "application/json"
            }),
            body: JSON.stringify(data)
        })
        .then(response => {
            responseStatus = response.status;
            if (response.status === 401){
                exit();
            }
            if (response.status === 403) {
                console.log('Unauthorized, Get Me a Modal');
            }
            return response.json()
        })
        .then(responseJson => {
            return {...responseJson, responseStatus}
        })
        .catch(err => {
            console.log(err)
            return err;
        })
    } catch(err) {
        return err;
    }
}

export const updateSolution = async (data, select) => {
    let responseStatus;
    let urlAnn = () => {
        if( select === 'clients') {
            return 'False'
        } else {
            return 'True'
        }
    }
    const annDb = urlAnn()
    try{
        return await fetch(`${baseURL}/users/solutions?annotation_db=${annDb}`, {
            method:"PUT",
            headers: new Headers({
                "Authorization": `Bearer ${token.access_token}`,
                "Content-Type": "application/json"
            }),
            body: JSON.stringify(data)
        })
        .then(response => {
            responseStatus = response.status;
            if (response.status === 401){
                exit();
            }
            if (response.status === 403) {
                console.log('Unauthorized, Get Me a Modal');
            }
            return response.json()
        })
        .then(responseJson => {
            return {...responseJson, responseStatus}
        })
        .catch(err => {
            console.log(err)
            return err;
        })
    } catch(err) {
        return err;
    }
}