import React, {useEffect, useState} from 'react';
import {useStore} from 'react-context-hook';
import ReactDOM from 'react-dom';
import {getAllBusinessModels} from "../../../endpoints/businessModel";
import {mapSelectiveModels} from "./functions";

const UpdateModel = (props) => {
    const {stateObject, selectModel, username} = props;

    const [allModels, setAllModels] = useState([]);
    const [selectedModelId, setSelectedModelId] = useState(null);
    const [select] = useStore('Select','clients')

    
    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";

        getAllBusinessModels(select).then(res=>{
            if(res.responseStatus === 200 && res.result){
                setAllModels(res.result)
            }
            // console.log(res)
        })
    }, [])
    const exitModal = () => {
        //if it is not above another modal 
        if(!props.upModal)
            document.body.style.overflowY = "auto";
        stateObject.setShowModels(false)
    }
    const changeModel = () => {
        if(selectedModelId!==null)
            selectModel(selectedModelId)
    }

    return ReactDOM.createPortal(
    <div onClick={exitModal} className="ui dimmer modals visible active">
        <div onClick={e=>e.stopPropagation()} className="ui standard modal visible active modal-class" style={{minWidth:"30%"}}>
            <div className="content">
                <div className="modal-title select">Update the Business Model of "{username}"</div>
                <div className="modal-subtitle select"> Choose a model from the ones below to change it</div>
                <div className="table-holder">
                <table className="admin-table model select" cellSpacing={0}>
                    <thead>
                        <tr >
                            <th className="t-head" >Business Model ID</th>
                            <th className="t-head" >Business Model Name</th>
                            <th className="t-head" >Duration In Days </th>
                        </tr>
                        
                    </thead>
                    
                    <tbody>
                    <hr className="thead-row"></hr>
                    {   
                    allModels && allModels.length>0?
                    mapSelectiveModels(allModels, selectedModelId, setSelectedModelId)
                        : null
                    }
                    </tbody>
                </table>
                </div>
            </div>
            <div className="actions">
                <button onClick={exitModal} className="button-all">Exit</button>
                <button onClick={changeModel} disabled={selectedModelId!==null?false:true} className={selectedModelId!==null?"button-create":"button-create forbidden"}>Change Business Model</button>
            </div>
        </div>
    </div>, document.getElementById('modal')
    )
}
export default UpdateModel;