export const getToken  = () => {   
    const token = JSON.parse(localStorage.getItem("loginToken"));
    if (token && token["access_token"] && token["access_token"].length > 0) 
    { 
        return true;
    }
    else
    {
        return false;
    }

}

export const exit = () =>{
    localStorage.removeItem("loginToken");
    window.location.assign('/login')
  }