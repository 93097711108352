import React, {useEffect} from 'react';
import ReactDOM from 'react-dom'
import {useHistory} from 'react-router-dom';

// Modal props expected:
// backTo prop, a string, showing the rout when hitting cancel or outside the model
// title prop, a string, showing the text in the modal
//add getBack prop as a function, so we dont reset shit the whole time



const LoginModal = (props) => {
    let history = useHistory();

    const goBack = () => {
        document.body.style.overflowY = "visible";
       props.getBack()
        history.push(props.backTo);
        // console.log("go back")
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";
    },[])

    return ReactDOM.createPortal(
        <div onClick={() => goBack()} className="ui dimmer modals visible active">
            <div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active basicc" style={props.wider?{minWidth:"35%"}:{minWidth:"30%"}}>
               
                <div className="content modal-wrapper wrapper-override" style={{padding:"0px"}}>
                    <div className="modal-delete-field"style={{padding:"2rem"}}>
                        <div><h3>{props.title}</h3></div>
                        <button onClick={() => {props.wider?props.getBack():goBack()}} className="button-create" style={{marginRight:"0rem"}}>OK</button>
                    </div>
                </div>
            </div>
        </div>, document.getElementById("modal")

    )
}

export default LoginModal;
