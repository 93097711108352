import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {exit} from "../../../endpoints/validation"; 

const UnauthorizedModal = (props) => {

    const {msg} = props;
    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";
    }, [])
    

    return ReactDOM.createPortal(
        <div className="ui dimmer modals visible active">
             <div onClick={e=>e.stopPropagation()} className="ui standard modal visible active modal-class-error" style={{minWidth:"20%"}}>
                <div className="modal-content">
                    <div className="modal-title">{msg}</div> 
                    <button onClick={exit} className="button-create">OK</button>
                </div>
                
             </div>
        </div>, document.getElementById('modal')
    )
}
export default UnauthorizedModal;