import {dropidFilesURL} from './baseURL';
import {exit} from './validation';
const token = JSON.parse(localStorage.getItem('loginToken'));
export const dropCache = async (version, username, select) => {
    let responseStatus; 
    let urlAnn = () => {
      if( select === 'clients') {
          return 'False'
      } else {
          return 'True'
      }
  }
  const annDb = urlAnn()
    // http://10.0.5.1/dev/v<version>/internal/cache/<username>
    try {
        return await fetch(`${dropidFilesURL}/v${version}/internal/cache/${username}?annotation_db=${annDb}`, {
            method: "DELETE",
            headers: new Headers({
                Authorization: "Bearer " + token.access_token
              })
        })
        .then(response => {
          responseStatus = response.status;
          if (response.status === 401) {
            exit()
          }
          return response.json()
        })
        .then(responseJson => {
          return {responseJson, responseStatus};
        })
        .catch(error => {
          console.log("Delete error:", error);
          return error;
        })
    } catch(e) { 
      return e 
    }

}