import {baseURL} from './baseURL';
import {exit} from './validation';
const token = JSON.parse(localStorage.getItem('loginToken'));


export const overView = async (client_id,solution,startDate, endDate, select) => {
    let responseStatus;
    let urlAnn = () => {
        if( select === 'clients') {
            return 'False'
        } else {
            return 'True'
        }
    }
    const annDb = urlAnn() 
    
    try{
        //http://10.0.5.1/dev/v3/admin/clients/91/solutions/1/report?start_date=2021-03-01&end_date=2021-04-01
        // console.log(`${baseURL}/clients/${client_id}/solutions/${solution}/report?start_date=${startDate}&end_date=${endDate}`)
        return await fetch(`${baseURL}/clients/${client_id}/solutions/${solution}/report?start_date=${startDate}&end_date=${endDate}&annotation_db=${annDb}`, {
            method: "GET",
            headers: new Headers({
                "Authorization": `Bearer ${token.access_token}`
            })
        })
        .then(response => {
            responseStatus = response.status;
            if (response.status === 401){
                exit();
            }
            if (response.status === 403) {
                console.log('Unauthorized, Get Me a Modal');
            }
            return response.json()
        })
        .then(responseJson => {
            return {...responseJson, responseStatus}
        })
        .catch(err => {
            console.log(err)
            return err;
        })
    } catch (err) {
        return err;
    }
}