import {idFilesURL} from "./baseURL";
import {exit} from './validation'

const token = JSON.parse(localStorage.getItem('loginToken'));
const getIdFiles = async (version, client_id, select ) => {
    let responseStatus; 
    let urlAnn = () => {
        if( select === 'clients') {
            return 'False'
        } else {
            return 'True'
        }
    }
    const annDb = urlAnn()
    try {
        return await fetch(`${idFilesURL}/v${version}/admin/files/${client_id}?annotation_db=${annDb}`, {
            method: "GET",
            headers: new Headers({
                "Authorization": `Bearer ${token.access_token}`,
                // "Content-Type": "application/json",
            })
        }) 
        .then(response => {
            responseStatus = response.status;
            if (response.status === 401){
               console.log('err')
              exit()
            }
            if (response.status === 403) {
                console.log('Unauthorized');
            }
            return response.json()
        })
        .then(responseJson => {
            return {...responseJson, responseStatus}
        }).catch(err => {
            console.log(err)
            return err;
        })
    } catch (err) {
        return err;
    } 
      
}

export default getIdFiles;