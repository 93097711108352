import React, {useState, useEffect} from "react";
// import {useStore} from "react-context-hook";
// import {updateSolution} from "../../endpoints/solutions";

const InputField = (props) => {
    const {attributeValue, attribute, save, analyticsState } = props;
    //state for value of input
    const [val, setVal] = useState("");
    //if editing is open or closed
    const [editClicked, setEditClicked] = useState(false);
    //dashboard reseter
    // const [reseter, setReseter] = useStore('reseter',false);

    useEffect(()=>{
        // console.log(attributeValue)
        if(attributeValue !== null  && attributeValue !== undefined){
            // console.log(attributeValue, 'prvio if')
            if(typeof attributeValue === 'string' || parseInt(attributeValue) <= 0) {
                setVal('Unlimited')
            } else {
                setVal(attributeValue);
            }
        }
    }, [attributeValue])

    // useEffect(() => {
    //     if(val )
    // }, [val])

    const saveFunc = () => {
        //dashboard version of the function
        if(!analyticsState){
            if(val!==""){
                if(parseInt(val) > 0){
                    save(val, attribute)
                } else {
                    save(0, attribute, analyticsState);
                    setVal('Unlimited')
                }
                setEditClicked(false);
            } 
            else {
                // console.log('')
            }
        }
        else {
            if (val!==''){
                if(parseInt(val) > 0) {
                    save(val, attribute, analyticsState);
                } else {
                    save(0, attribute, analyticsState);
                    setVal('Unlimited')
                }
                // save(val, attribute, analyticsState);
                setEditClicked(false);
            }
        }
        
    }

//     <select
//           onchange="document.getElementById('displayValue').value=this.options[this.selectedIndex].text;                 document.getElementById('idValue').value=this.options[this.selectedIndex].value;">
//     <option></option>
//     <option value="one">one</option>
//     <option value="two">two</option>
//     <option value="three">three</option>
//   </select>
//   <input type="text" name="displayValue" id="displayValue" 
//          placeholder="add/select a value" onfocus="this.select()"
//     >
//   <input name="idValue" id="idValue" type="hidden"></input>


    return (
        <form className="direction-row centered" onSubmit={(e)=>{e.preventDefault(); saveFunc()}}>
            <input 
                disabled={editClicked?false:true}
                className="td-input"
                type="text"
                value={val}
                list="data"
                onChange={(e)=>setVal(e.target.value)}
                />
                <datalist id="data">
                    <option value="Unlimited"/>
                </datalist>
                <div className="button-no-style">
                {
                    editClicked===false?
                    <div className="main-icons" onClick={()=>{setEditClicked(true)}}>
                        <i className= "pencil alternate icon iconTrashExtra cursore"></i>
                    </div>
                    : <div className="main-icons" onClick={saveFunc} >

                        <i className= "save icon iconTrashExtra cursore"></i>
                    </div>
                }
                </div>
                <button className={editClicked?"button-no-style on-icon cursore":"hidden"} type="button" onClick={()=>setEditClicked(false)}>
                        <div className="main-icons">
                                <i className = "times icon iconTrashExtra "></i>
                        </div>
                    </button>
        </form>
        )
}

export default InputField;