import React from 'react'

export const showPageOptions = (pagesNumber, paginationNumber, setPaginationNumber, scrollId) => {

    let pages = [];
    const scrollingToTop = (id) => {
        // window.scrollTo({ top:0,behavior: 'smooth'})
        // const myDiv = document.getElementById(id);
        // if(myDiv !== undefined && myDiv!==null){
        //     window.scrollTo({
        //       top:myDiv.offsetTop,
        //       behavior:"smooth"
        //   });} 
    }
    
    const onWheel = e => {
        e.stopPropagation() 
        const container = document.getElementById('scrollContainer');
        const containerScrollPosition = document.getElementById('scrollContainer').scrollLeft;
        container.scrollTo({
          top: 0,
          left: containerScrollPosition + e.deltaY
        });
      };
    
    for(let i = 1; i<=pagesNumber; i++){
        pages.push(i);
    }
    const showFirst = (page) => { 
        // display selected page
        if(page===paginationNumber){
            return (
                <div className="pagination-item">
                    <div className={`pagination-num circle-unit-selected `} key={page}
                     onClick={()=>setPaginationNumber(page)}>
                        <div className="selected-unit"> {page} </div>
                    </div>
                    
                </div>
            )
        }
        // display first pages if selected page is smaller than or equal to three
        else if (paginationNumber<3) {
            if( page<=5 && page !== paginationNumber)
                return ( <div className="pagination-item">
                            <div className={`pagination-num circle-unit `} key={page}
                                onClick={()=>setPaginationNumber(page)}>
                                <div className={`option-unit `}> {page} </div>
                            </div>
                            </div> )
        }
        //display last pages if paginationNumber is 3 steps away from final
        else if (pagesNumber-paginationNumber <= 2  && page!==paginationNumber) {
            if( pagesNumber - page < 5) {
                return ( <div className="pagination-item">
                            <div className={`pagination-num circle-unit `} key={page}
                                onClick={()=>setPaginationNumber(page)}>
                            <div className={`option-unit `}> {page} </div>
                        </div>
                        </div> )
            }
            
            if(page<paginationNumber && paginationNumber-page<=2 &&page!==paginationNumber) {
                return ( <div className="pagination-item">
                            <div className={`pagination-num circle-unit `} key={page}
                                onClick={()=>setPaginationNumber(page)}>
                            <div className={`option-unit `}> {page} </div>
                        </div>
                        </div> )
            }
        }
        // display pages around if paginationNumber is somewhere in middle
        else if (pagesNumber-paginationNumber > 2 &&  paginationNumber  >2 )  {
            if((paginationNumber-page<3 && paginationNumber>page)||(page>paginationNumber && page-paginationNumber<3)) {
                return ( <div className="pagination-item">
                            <div className={`pagination-num circle-unit `} key={page}
                                onClick={()=>setPaginationNumber(page)}>
                            <div className={`option-unit `}> {page} </div>
                        </div>
                        </div> )
            }
            
        }
        
    }

    let count = 0;

    return (
        <div className="pagination">
         <div onClick={()=>{goFirst(pagesNumber, paginationNumber, setPaginationNumber);}}
                className={paginationNumber!==1 ?`  option-unit `: ` option-unit-gray `}><i className="angle double left icon"></i></div>
            <div  onClick={()=>{goPrev(pagesNumber, paginationNumber, setPaginationNumber); }}
                        className={`option-unit `}> <i className="angle left icon"> </i> </div>
                <div  className={` scrollContainer scroll`} id="scrollContainer" onWheel={onWheel} > 
                    {pages.map(page=>{
                        return(
                            <div key={count++}>
                                {
                                    (page === paginationNumber ? (
                                    <div id="selected-page" className="pagination-item">
                                        <div className={`pagination-num circle-unit-selected`} key={page}
                                        onClick={()=>{setPaginationNumber(page);}}>
                                            <div className={`selected-unit `}> {page} </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="pagination-item">
                                        <div className={`pagination-num circle-unit`} key={page}
                                            onClick={()=>{setPaginationNumber(page); }}>
                                            <div className={`option-unit `}> {page} </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        )
                    })}
                    </div> 
                    <div  onClick={()=> {goNext(pagesNumber, paginationNumber, setPaginationNumber)}}
                        className={`option-unit `}> <i className="angle right icon"> </i> </div>
                 <div 
                onClick={()=>{goLast(pagesNumber, paginationNumber, setPaginationNumber)}}
                className={pagesNumber !== paginationNumber ? `option-unit ` : ' option-unit-gray' }><i className="angle double right icon"></i></div>
            </div>
    )
}

const goNext = (pagesNumber, paginationNumber, setPaginationNumber) => {
    let number = paginationNumber;
    if(pagesNumber!==paginationNumber){
        number++;
        setPaginationNumber(number)
    } else {
        setPaginationNumber(1);
    }
}

const goPrev = (pagesNumber, paginationNumber, setPaginationNumber) => {
    let number = paginationNumber;
    if(paginationNumber!==1){
        number--;
        setPaginationNumber(number)
    } else {
        setPaginationNumber(pagesNumber);
    }
}

const goFirst = (pagesNumber, paginationNumber, setPaginationNumber) => {
    if(paginationNumber!==1){
        setPaginationNumber(1)
    } 
}

const goLast = (pagesNumber, paginationNumber, setPaginationNumber) => {
    if(pagesNumber!==paginationNumber){
        setPaginationNumber(pagesNumber)
    }
}
