import {baseLoginApiURL} from './baseURL';
import {exit} from "./validation";

export const login = (data) => {
    try {
      return fetch(`${baseLoginApiURL}/login`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          // console.log("Login error: ", error);
          return error;
        });
    } catch (errors) {
      // console.log("Login error in try-catch: ", errors);
      return errors;
    }
};

export const logout = async (token) => {
   
    // const token = JSON.parse(localStorage.getItem("loginToken"));
    let accessRevoke;
    let refreshRevoke;
    try {
      accessRevoke = await fetch(
        `${baseLoginApiURL}/logout/access_revoke`,
        {
          method: "DELETE",
          headers: new Headers({
            Authorization: "Bearer " + token.access_token
          })
        }
      );
    } catch (error) {
      // console.log("error:", error);
    }
    try {
      refreshRevoke = await fetch(
        `${baseLoginApiURL}/logout/refresh_revoke`,
        {
          method: "DELETE",
          headers: new Headers({
            Authorization: "Bearer " + token.refresh_token
          })
        }
      );
    } catch (error) {
      // console.log("error:", error);
    }

    // if (accessRevoke && refreshRevoke === 200) {
    // console.log("accessRevoke", accessRevoke, "refreshRevoke", refreshRevoke);
    exit();
    // }
  };