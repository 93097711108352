import React, { useEffect } from 'react'
import ErrorModal from '../UtilityModals/ErrorModal'
import { useStore } from 'react-context-hook'

const CreateComfirmModal = (props) => {
    const {closeModalSmall, userRegistrationInfoObject, funk, errorMsg, setErrorMsg, addSolutionInfo, addSolutionFunk} = props
    const [select] = useStore('Select','clients')

    const goBack = () => {
        closeModalSmall(false)
        document.body.style.overflowY = "auto";
    }

    

    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden";
    }, [])
    // console.log(userRegistrationInfoObject)
    const findBiznisModel =userRegistrationInfoObject? userRegistrationInfoObject.businessModels.filter((biz) => biz.bm_id === parseInt(userRegistrationInfoObject.businessModelId)) : null
    const findBiznisModeladdSolution =addSolutionInfo? addSolutionInfo.businessModels.filter((biz) => biz.bm_id === parseInt(addSolutionInfo.selectedBusinessModel)) : null
    // console.log(findBiznisModeladdSolution)
    
    return (
        <div className="ui dimmer modals visible active" style={{zIndex: '1001'}}>
                <div className=" ui standard modal visible active upload-new">
                    <div className="header"><h1>Last Check Up</h1></div>
                    <div className="content">
                        
                        {  
                        userRegistrationInfoObject?
                            <div>
                                <h3 className="registerKeys">Username: <span className="registerLocks">{userRegistrationInfoObject.username}</span></h3>
                            <h3 className="registerKeys">Password: <span className="registerLocks">{userRegistrationInfoObject.password}</span></h3>
                            <h3 className="registerKeys">Email: <span className="registerLocks">{userRegistrationInfoObject.mail}</span></h3> 
                            <h3 className="registerKeys">Company: <span className="registerLocks">{userRegistrationInfoObject.company}</span></h3> 
                            <h3 className="registerKeys">Business Model: <span className="registerLocks">{findBiznisModel[0]? findBiznisModel[0].name : 'No Business Model'}</span></h3> 
                            <h3 className="registerKeys">Duration In Days: <span className="registerLocks">{userRegistrationInfoObject.selectedDuration}</span></h3> 


                            <h3 className="registerKeys">Selected Solutions: 
                                <span className="registerLocks padding at">{userRegistrationInfoObject.at ? "Automatic Tagging" : null}</span>
                                
                                <span className="registerLocks padding sr">{userRegistrationInfoObject.sr ? 'Similar Recommendations' : null}
                                </span> 
                                <span className="registerLocks padding as">{userRegistrationInfoObject.as ? 'Auto Rescale' : null}
                                </span> 
                                <span className="registerLocks padding vs">{userRegistrationInfoObject.vs ? 'Visual Search' : null}
                                </span>
                            </h3>


                            <h3 className="registerKeys">Image Limitations: <span className="registerLocks">{parseInt(userRegistrationInfoObject.limitation) > 0 ?userRegistrationInfoObject.limitation : "Unlimited"}</span></h3>
                            <h3 className="registerKeys">Daily Image Limit: <span className="registerLocks">{parseInt(userRegistrationInfoObject.dailyLimit) > 0 ? userRegistrationInfoObject.dailyLimit : "Unlimited"}</span></h3>
                            </div>
                         :null}


                         {
                             addSolutionInfo?
                             <div>
                                 <h3 className="registerKeys">Selected Solutions: 
                                    <span className="registerLocks padding at">{addSolutionInfo.selectedSol === "1" ? "Automatic Tagging" : null}</span>
                                    
                                    <span className="registerLocks padding sr">{addSolutionInfo.selectedSol === "3" ? 'Similar Recommendations' : null}
                                    </span> 
                                    <span className="registerLocks padding as">{addSolutionInfo.selectedSol === "4" ? 'Auto Rescale' : null}
                                    </span> 
                                    <span className="registerLocks padding vs">{addSolutionInfo.selectedSol === "2" ? 'Visual Search' : null}
                                    </span>
                                </h3>

                                <h3 className="registerKeys">Business Model: <span className="registerLocks">{findBiznisModeladdSolution[0]? findBiznisModeladdSolution[0].name : 'No Business Model'}</span></h3>
                                <h3 className="registerKeys">Duration In Days: <span className="registerLocks">{addSolutionInfo.selectedDuration}</span></h3> 

                                <h3 className="registerKeys">Image Limitations: <span className="registerLocks">{parseInt(addSolutionInfo.limitation) > 0 ? addSolutionInfo.limitation : 'Unlimited'}</span></h3>
                                <h3 className="registerKeys">Daily Image Limit: <span className="registerLocks">{parseInt(addSolutionInfo.dailyLimit) > 0 ? addSolutionInfo.dailyLimit : 'Unlimited'}</span></h3>
                             </div>
                         : null}

                            
                        
                        
                    </div>
                    <div className="actions">
                        <button onClick={() => goBack()} className="button-all">Cancel</button>
                        <button onClick={() => {funk(addSolutionInfo); goBack();} } className="button-create">Confirm</button>
                        {/* {addSolutionInfo ?<button onClick={() => {addSolutionFunk(addSolutionInfo); goBack()} } className="button-create">Confirm</button> : null} */}
                        
                    </div>   
                </div>
                {errorMsg? <ErrorModal msg={errorMsg} exitFunc={()=>setErrorMsg(null)} /> 
                : null}
        </div>
    )
}

export default CreateComfirmModal
