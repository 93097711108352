import React, {useState, useEffect} from "react";
import {useStore} from "react-context-hook";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import {getDashboardPage} from "../../endpoints/dashboard";
import {searchUser} from "../../endpoints/searchUser";
import {mapThroughUsers} from "./functions";
import AllModels from "../Modals/BusinessModels/BizModels";
import UnauthorizedModal from "../Modals/UtilityModals/UnauthorizedModal";
import Register from "../Modals/Register/Register";
import ErrorModal from "../Modals/UtilityModals/ErrorModal";
import {showPageOptions} from "../SecondaryNavbar/Pagination";
import {exit} from '../../endpoints/validation'
// import Calendar from './Calendar'

const Dashboard = () => {
    const [adminDashboard, setAdminDashboard] = useState([]);
    const [showAllModels, setShowAllModels] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const [pagesNumber, setPagesNumber] = useState(null);
    const [pageNumber, setPageNumber] = useStore('pageNumber', 1);
    const [username, setUsername] = useState('');

    const [register, setToRegister] = useStore('register', false);
    const [failMsg, setFailMsg] = useStore('fail', null);
    // const [addSolution, setAddSolution] = useStore(false);
    const [searchOption, setSearchOption] = useState('')

    const [orderBy, setOrderBy] = useState('client_id');
    const [order, setOrder] = useState('asc')
    const [toggle, setToggle] = useState('true')
    const [queryURL, setQueryUrl] = useStore('queryURL', '')
    const [select, setSelect] = useStore('Select','clients')

    const [reseter, setReseter] = useStore('reseter',false);
    let scrollId="containerScrollDiv";
    const stateObject = {
        adminDashboard,
        setAdminDashboard,
        reseter,
        setReseter, 
        showAllModels,
        setShowAllModels,
        register,
        setToRegister,
        pagesNumber,
        setPagesNumber,
        pageNumber,
        setPageNumber,
        username,
        setUsername,
        toggle,
        setToggle,
        searchOption,
        setSearchOption,
    }

    const changeToggle = () => {
        if(toggle === 'true') {
            setToggle('false')
        } else if (toggle === 'false') {
            setToggle('true')
        }
    }

    useEffect(()=>{   
        if(username === '') {
            getDashboardPage(pageNumber, queryURL).then(res => {
                if(res && res.responseStatus === 200 && res.total_row_count && res.result){
                    setPagesNumber(Math.ceil(res.total_row_count / 20))
                    setAdminDashboard(res.result);
                }
                else if(res && res.responseStatus === 403){
                    setErrorMsg(res.detail)
                }
            })
        } else {
            searchUser(stateObject.username, stateObject.searchOption, select).then(res => {
                if(res && res.responseStatus === 200 && res.result){
                    
                    setPagesNumber(0)
                    stateObject.setAdminDashboard(res.result);
                } else if(res && res.responseStatus === 401) {
                    setErrorMsg(res.detail)
                    exit();
                }
                else if(res && res.responseStatus !== 200){
                    setFailMsg(res.detail)
                }
            })
        }
        
    },[reseter, pageNumber, queryURL])

    useEffect(()=>{
        const selectToggle = () => {
            if(select === 'annotators') {
                return 'True'
            } else {
                return 'False'
            }   
        }
        let annToggle = selectToggle()

        setQueryUrl(`?order_by=${orderBy}&order=${order}&include_all=${toggle}&annotation_db=${annToggle}`); //&include_all=false --- kopce so ke mene true false(string ne bullean true default) 
        
        
    },[orderBy, order, toggle, select])

    useEffect(()=>{
        const scrollTo= document.getElementById('selected-page')
            console.log(scrollTo)
        if(scrollTo !== null && scrollTo !== undefined){
            scrollTo.scrollIntoView({top:0,behavior:"smooth"})
        }
      },[pageNumber])
    const filterDashboard = (order_by) => {
        setOrderBy(order_by);
        orderBy===order_by && order==='asc'?setOrder('desc'):setOrder('asc')
    }

return (
    <div className="dashboard" id="containerScrollDiv">
        <SecondaryNavbar stateObject={stateObject} changeToggle={changeToggle}/>
        <table className="admin-table" cellSpacing={0}>
            <thead className="admin-head">
                <tr>
                    <th className="t-head cursore"
                    onClick={()=>filterDashboard('client_id')}
                    >Client ID
                     <i className={orderBy==="client_id"&&order==="desc"?"angle up icon":"angle down icon"}></i>
                    </th>
                    <th className="t-head cursore"
                        onClick={()=>filterDashboard('username')}
                         >Username
                        <i className={orderBy==="username"&&order==="desc"?"angle up icon":"angle down icon"}></i>
                        </th>
                    <th className="t-head fixed-width-mail cursore" 
                        onClick={()=>filterDashboard('email')}
                        >E-mail 
                        <i className={orderBy==="email"&&order==="desc"?"angle up icon":"angle down icon"}></i>
                    </th>
                    <th className="t-head cursore hide"
                    onClick={()=>filterDashboard('company')}
                    >
                        Company
                        <i className={orderBy==="company"&&order==="desc"?"angle up icon":"angle down icon"}></i>
                        </th>
                    <th 
                    className="t-head cursore"
                    onClick={() => filterDashboard('days_until_expiration')}
                    >
                        Days Until Expiration
                        <i className={orderBy==="days_until_expiration"&&order==="desc"?"angle up icon":"angle down icon"}></i>
                    </th>
                    <th className="t-head fixed-width-mail cursore" 
                        onClick={()=>filterDashboard('solution_name')}
                        >Solution Name
                        <i className={orderBy==="solution_name"&&order==="desc"?"angle up icon":"angle down icon"}></i>
                        </th>
                    <th className="t-head" >Limitation</th>
                    <th className="t-head" >Limitation By Day</th>
                    <th className="t-head" >Active</th>
                    <th className="t-head hide" >Expires on</th>
                    <th className="t-head  cursore" 
                    onClick={()=>filterDashboard('reactivated_at')}>
                            Reactivated At 
                        <i className={orderBy==="reactivated_at,"&&order==="desc"?"angle up icon":"angle down icon"}></i>
                    </th>
                    <th className="t-head cursore" 
                        onClick={()=>filterDashboard('business_model_id')}
                        >Business Model 
                         <i className={orderBy==='business_model_id'&&order==="desc"?"angle up icon":"angle down icon"}></i>
                        </th>
                    {/* <th className="t-head"><i className="angle down icon"></i></th> */}
                </tr>
            </thead>
            <tbody>
                {mapThroughUsers(adminDashboard, stateObject)}
            </tbody>
            
        </table>
            {
                showAllModels?
                <AllModels stateObject={stateObject} />
                : null
            }
            {
                errorMsg? <UnauthorizedModal msg={errorMsg} /> : null
            }
            {
                register? <Register stateObject={stateObject} /> : null
            }
            {
                failMsg? <ErrorModal 
                msg={failMsg} exitFunc={()=>setFailMsg(null)} /> : null
            }
        <div className="pagination">
            {
                stateObject.pagesNumber ? showPageOptions(pagesNumber, pageNumber, setPageNumber, scrollId ) : null
            }
        </div>
    </div>
    )
}

export default Dashboard;