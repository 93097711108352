import {baseURL} from './baseURL';
import {exit} from './validation';
const token = JSON.parse(localStorage.getItem('loginToken'));

export const searchUser = async (colValue, urlQuery = '', select) => {
    let responseStatus;
    let urlAnn = () => {
        if( select === 'clients') {
            return 'False'
        } else {
            return 'True'
        }
    }
    const annDb = urlAnn()

    try{
        // console.log(`${baseURL}/users/dashboard/${colValue}${urlQuery}`)
        return await fetch(`${baseURL}/users/dashboard/${colValue}?query_by=${urlQuery}&annotation_db=${annDb}`, {
            method: "GET",
            headers: new Headers({
                "Authorization": `Bearer ${token.access_token}`
            })
        })
        .then(response => {
            responseStatus = response.status;
            if (response.status === 401){
                exit();
            }
            if (response.status === 403) {
                console.log('Unauthorized, Get Me a Modal');
            }

            return response.json()
        })
        .then(responseJson => {
            return {...responseJson, responseStatus}
        })
        .catch(err => {
            console.log(err)
            return err;
        })
    } catch (err) {
        return err;
    }
}