import React, {useState, useEffect} from "react";
import {useStore} from 'react-context-hook';
import getidFiles from '../../endpoints/idFiles';
import updateIdFiles from '../../endpoints/idFilesUpdate';
import { dropCache } from "../../endpoints/dropCache";

import ConfirmationModal from '../Modals/UtilityModals/ConfirmationModal'
import ErrorModal from "../Modals/UtilityModals/ErrorModal";

import {getidFilesTitle,
        getidFilesInfo} from "./functions";


const IdFilesModal = (props) => {
    const { closeModal, user} = props;
    const [version,setVersion] = useState("4")

    const[idFiles, setIdFiles] = useState({})
    const[selectedInfo, setSelectedInfo] = useState('client_config_json');
    const [editedKey, setEditedKey] =  useState([]);
    const client_id = user.client_id;
    const username= user.username;
     
    const [err , setErr] = useState();
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [message, setMessage] = useState()
    const [select] = useStore('Select','clients')
    
    const idFilesState = {
        idFiles,
        setIdFiles,
        selectedInfo,
        setSelectedInfo,
        version,
        setEditedKey,
        editedKey
    }
    const [deleted, setDeleted] = useState(false)
    const [delErr, setDelErr] = useState(false)
    //console.log(idFiles);
    //console.log(version)
     useEffect(()=>{
        getidFiles(version, client_id, select)
        .then(res=>{  
            //   console.log(res, version)
               if(res && res.responseStatus === 200){
                   setIdFiles(res.result)
               }else{
                   setErr(true)
               }
        }) 
     }, [user, version]
     )
     
    // console.log(client_id)
    //  console.log(editedKey)

    // update 
    const updateHandler = (version, client_id, data) => {
      
        if(Object.keys(data.length>1)){
            updateIdFiles(version, client_id, data, select).then(res=>{
                // console.log(res)
            if(res.status !== "OK"){
                <div> Something's wrong. Try again </div>
            } else {
                setMessage("Update successfully")
                setConfirmationModal(true);
                getidFiles(version, client_id, select).then(res=>{  
                    if(res && res.responseStatus === 200){
                        setIdFiles(res.result)
                    // console.log(res)
                    }else{
                        setErr(true)
                    }
                }) //end of getidFiles
            } 
        })
        }
        // if(!data.length){
        //     setMessage("You need to make some changes to Update")
        //     setConfirmationModal(true);
        //  }
    } 
    


    //  Drop Handler
    const dropHandler = (version, username) => {
        dropCache(version, username, select).then(res=>{
            //console.log(res)
            if(res && res.responseStatus === 200){
                setMessage("Cache Droped")
                setDeleted(true)
            }else{
                setMessage("Failed")
                setDelErr(true)
            }
        })   
    }
  

    const goBack = () => {
        closeModal(false)
        document.body.style.overflowY = "auto"; 
    }
    const onChangeVersion = (e) => {
        setIdFiles({});
        setErr(false)
        setVersion(e.target.value);
    }
    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden"; 
    },[])
    return <div  onClick={() => goBack()} className="ui dimmer modals visible active" >

        <div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active modal-class basicc">
            <div className="idFiles">
                <select className="ui dropdown" onChange={(e)=> onChangeVersion(e) }>
                    <option value="4">Version v4</option>
                    <option value="4.1">Version v4.1</option>
                    <option value="4.2">Version v4.2</option>
                    <option value="3">Version v3</option>
                    <option value="1">Version v1</option>
                </select> 
                <div>   
                   {/* <span>UserName:</span>  */}
                   <span className="usernameIdFiles">{username}</span>
                </div>
            </div>
            <div className="direction-row titles">
                {err ? <h2 className="errMsg" > Something's wrong. </h2> : null }
                {
                  idFiles && Object.keys(idFiles).length>0? getidFilesTitle(idFilesState) : null
                }
            </div>
            <div>
                {
                    idFiles && Object.keys(idFiles).length>0?
                    getidFilesInfo(idFilesState): null
                }
            </div>
           
            <div className = "infoIdbtn">
                <button onClick={() => goBack()} className="button-all">Close</button>
                <button className="update button-all" onClick={() => updateHandler(version, client_id, editedKey)} >Update</button>
                <button className="dropCache button-all" onClick={() => dropHandler(version, username)} >Drop Cache</button>

            </div>
        </div>
        {confirmationModal ? <ConfirmationModal msg={message} closeModal={setConfirmationModal} /> : null
        }

        {
           deleted ?  <ConfirmationModal msg={message} closeModal={setDeleted} /> : null
        }
        {
            delErr ? <ErrorModal msg={message} exitFunc={()=>setDelErr(null)} /> : null
        }
       
    </div>
}
export default IdFilesModal;