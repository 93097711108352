import React, {  useEffect , useState} from "react";
import {useStore} from 'react-context-hook';
import { Chart } from 'react-charts'
// import moment from 'moment'
const ClientsActivity = () =>{
    // const [analytics,] = useStore("analytics",[])
    const [clientsActivity,]=useStore("clientsActivity",[])
    //moment(activity.date).format('DD-MM-YYYY')

    const [data,setData] = useState([])
    useEffect(()=>{
      if(clientsActivity.length!==0)
      setData([
        {
          label: 'Clients activity',
          color: "rgb(85,111,181)",
          fontStyle:"Avenir",
          lineWidth:5,
          data : clientsActivity
        }
      ]) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[clientsActivity])
     
      const axes = React.useMemo(
        () => [
          { primary: true, type: 'ordinal',position: 'bottom',},
          { type: 'linear', position: 'left',showGrid:false }
        ],
        []
      )
    // console.log(analytics)
    // console.log(clientsActivity)
    // console.log(data)

    return(
      <div className="clients-activity">
          <div className="column-direction" style={{height:"100%"}}>
            <div className="direction-row"  style={{marginBottom:"1rem"}}>
              <div className="title">Daily Client Activity</div>
              <div className="status-radial">Last 30 Days</div>
            </div>
            <div style={{width: '100%',height: '100%'}}>
            {
              <Chart data={data} axes={axes} tooltip 
              options ={{
                scales: {
                      xAxes: [{
                        gridLines: {
                          color: "rgba(0, 0, 0, 0)",
                        }
                      }],
                      yAxes: [{
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        }
                      }]
                }
              }} />
            }
            </div>
          </div>
      </div>
    )
}

export default ClientsActivity