import React from 'react';
import PrimaryRow from './PrimaryRow';
export const mapThroughUsers = (adminDashboard, stateObject) => {
    if (adminDashboard.length === 0){
        return null
    }
    return adminDashboard.map((user, index) => {
        return( 
            <PrimaryRow key={index} user = {user} stateObject={stateObject}/>
            )
    })
}

