import {baseURL} from './baseURL';

const token = JSON.parse(localStorage.getItem('loginToken'));

export const SendMail = (clientId) => {
    try {
      return fetch(`${baseURL}/users/mail/${clientId}`, {
        method: "POST",
        headers: new Headers({
            "Authorization": `Bearer ${token.access_token}`,
            "Content-Type": "application/json"
        })
      })
        .then(response => response.json())
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          // console.log("Login error: ", error);
          return error;
        });
    } catch (errors) {
      // console.log("Login error in try-catch: ", errors);
      return errors;
    }
};