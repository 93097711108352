import {baseURL} from './baseURL';
import {exit} from './validation';

const token = JSON.parse(localStorage.getItem('loginToken'));

export const getDashboard = async () => {
   
    let responseStatus; 
    try {
        return await fetch(`${baseURL}/dashboard`, {
            method: "GET",
            headers: new Headers({
                "Authorization": `Bearer ${token.access_token}`
            })
        })
        .then(response => {
            responseStatus = response.status;
            if (response.status === 401){
                exit();
            }
            if (response.status === 403) {
                console.log('Unauthorized, Get Me a Modal');
            }
            return response.json()
        })
        .then(responseJson => {
            return {...responseJson, responseStatus}
        })
        .catch(err => {
            console.log(err)
            return err;
        })
    } catch (err) {
        return err;
    } 
}

export const getDashboardPage = async (pageNr = 1, queryParam = '') => {
    let responseStatus; 
    try {
        return await fetch(`${baseURL}/dashboard/pages/${pageNr}${queryParam}`, {
            method: "GET",
            headers: new Headers({
                "Authorization": `Bearer ${token.access_token}`
            })
        })
        .then(response => {
            responseStatus = response.status;
            if (response.status === 401){
                exit();
            }
            if (response.status === 403) {
                console.log('Unauthorized, Get Me a Modal');
            }
            return response.json()
        })
        .then(responseJson => {
            return {...responseJson, responseStatus}
        })
        .catch(err => {
            console.log(err)
            return err;
        })
    } catch (err) {
        return err;
    } 
}