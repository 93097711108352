import React, {useEffect, useState} from 'react';
import { useStore } from 'react-context-hook';
import ReactDOM from 'react-dom'
import ConfirmationModal from '../UtilityModals/ConfirmationModal'
import {SendMail} from '../../../endpoints/SendMail';
import {useHistory} from 'react-router-dom';

// Modal props expected:
// backTo prop, a string, showing the rout when hitting cancel or outside the model
// title prop, a string, showing the text in the modal
// accept prop, a string, showing the text on the "accept" button, or the confirmative scenario
// decline prop, a string, showing the text on the "decline" button, or the negative scenario
// acceptHandler prop, a function, doing the action needed for this modal
//add getBack prop as a function, so we dont reset shit the whole time



const ModalPixyle = (props) => {
    const {closeModal, user, msg, funk, confirmationMsg, closeBigModal} = props
    // let history = useHistory();
    // const [, setModalIsOn] = useStore("modalIsOn", false);
    // const [solutionShortName,] = useStore("solutionShortName","")
    const [openModal, setOpenModal] = useState(false)
    const [reseter, setReseter] = useStore('reseter',false);
    const [select] = useStore('Select','clients')

    const goBack = () => {
        document.body.style.overflowY = "auto";
        closeModal(false)
    }

    useEffect(()=>{
        //scroll to the top and prevent background scrolling
        window.scrollTo(0, 0);
        document.body.style.overflowY = "hidden"; 
    },[])

    return ReactDOM.createPortal(
        <div onClick={() => goBack()} className="ui dimmer modals visible active">
            <div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active modal-class basicc" style={{minWidth:"30%"}}>
                <div className="content modal-wrapper wrapper-override" style={{padding:"0px"}}>
                    <div className="modal-delete-field" style={{padding:"2rem"}}>
                        <div><h3 className="modal-h3">{msg}</h3></div>
                    
                        <div className="modal-delete-buttons">
                            <button onClick={() => goBack()} className="button-all YesNo">No</button>
                            <button onClick={()=> {funk(user, select); setOpenModal(true); reseter === true ? setReseter(false) : setReseter(true)}} className="button-login YesNo">Yes</button>
                        </div> 
                    </div>
                </div>
            </div>
            {
                openModal ? <ConfirmationModal closeModal={closeModal} msg={confirmationMsg} closeBigModal={closeBigModal}/> : null
            }
        </div>, document.getElementById("modal")

    )
}

export default ModalPixyle;
