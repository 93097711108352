import React, {useState} from "react";
import ErrorModal from "../Modals/UtilityModals/ErrorModal";
import {useStore} from "react-context-hook";

const SearchUser = (props) => {
    
    const [errorMsg, setErrorMsg] = useState(null);  
    const {stateObject} = props;
    const [reseter, setReseter] = useStore('reseter',false);
    const [select, setSelect] = useStore('Select','clients')
    const [pageNumber, setPageNumber] = useStore('pageNumber', 1);
    
    const changeReseter = () => {
        if(reseter === true) {
            // console.log('trueeeeeeeee')
            setReseter(false)
        } else if (reseter === false) {
            setReseter(true)
            // console.log('falseeeeeeee')
        }
    }
    
    const handleSelect = (e) => {setSelect(e.target.value); setPageNumber(1)}
    
    const submitForm = e => {
        // let queryURL = `?order_by=${stateObject.orderBy}&order=${stateObject.order}&include_all=${stateObject.toggle}`
        e.preventDefault()
        changeReseter()
    }

    return (
        <>
        <form onSubmit={submitForm} className="ui icon input search-user">
            <i className="search icon"></i>
            <input 
            value = {stateObject.username}
            onChange = {e=>stateObject.setUsername(e.target.value)}
            type="text" placeholder="Search User By Username..." />
            {
                errorMsg? <ErrorModal msg={errorMsg} exitFunc={()=>setErrorMsg(null)}/> : null
            }
        </form>
        <select className="searchOption" onChange={e=>stateObject.setSearchOption(e.target.value)}>
        <option value='' disabled selected hidden>Search By</option>
        <option value='username'>Username</option>
        <option value='company'>Company</option>
        <option value="business_model_name">Business Model</option>
    </select>
    <select className="searchOption" value={select} onChange={handleSelect}>
        <option value='clients'>Clients </option>
        <option value='annotators'>Annotators </option>
    </select>
    </>
    )
}
export default SearchUser;