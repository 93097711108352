import React, {useState} from "react";
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';


// get the title / Regex
const getTitle = (title) => {
        let delID = title.replace("id", "");
        let delJ =delID.replace("json", "");
        let delCvs = delJ.replace("csv", "")
        let a = delCvs.split("_");
        let ret = '';
        for(let i = 0; i<a.length; i++)
          ret = ret + " " + a[i]
      
        return ret;    
}

export const getidFilesTitle = ( idFilesState) => {
    
    return Object.entries(idFilesState.idFiles).map(([key, value])=>{
        // console.log(key);
        return (
            <button key={key} onClick={()=>idFilesState.setSelectedInfo(key)} className= {idFilesState.selectedInfo === key ? "button-idFiles selected" : "button-idFiles"}  >
                 <div className="text">{getTitle(key)}</div> 
            </button>
        )         
    } )

  }

  export const getidFilesInfo = (idFilesState, editedKey) => {  
    return Object.entries(idFilesState.idFiles).map(([key, val])=>{
     
        if(key === idFilesState.selectedInfo){
         return <div className="idFileInfo" >
               
              <Editor
                    key = {key}
                    value={val}
                    mode={"code"}
                    onChange={(value) =>
                      { idFilesState.setIdFiles({...idFilesState.idFiles, [key] : value});
                        idFilesState.setEditedKey({...idFilesState.editedKey, [key] : value});
                    }

                  }
                >  </Editor>
         </div>
        }
    })
  }